import { Box, Checkbox, Divider, FormControl, Grid, TextField, Typography } from "@material-ui/core";
import { convertToCurrencyString, formatCurrency } from "common/Utils/currency";
import { getFilteredOrders, getVisibleOrders, groupeBySku } from "common/Utils/orders";
import { FbaFee, Order, Period, ReturnItem, SaleTraffic, } from "common/types";
import PeriodSelection from "components/shared/PeriodSelection";
import { TranslationPrefixes } from "consts/translationPrefixes";
import { CurrencyContext } from "context/currency";
import { endOfDay, startOfDay } from "date-fns";
import useToggleSelect from "hooks/useToggleSelect";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFees, getOrders, getReturnReportData, getSalesAndTraffic } from "services/service";
import { getColumns, getDynamicColumnsForExport } from "../../common/Utils/ActionUtils";
import { TableComponentToPrint } from "../ComponentToPrint/TableComponent";
import CardComponent from "../shared/CardComponent";
import { CardHeaderActions } from "../shared/CardHeaderActions";
import TableComponent, { Column } from "../shared/TableComponent";

const getCsvData = (data: any[]) => {
    return data.map(el => {
        Object.keys(el).forEach(key => {
            if (typeof el?.key === "string") {
                el[key] = el[key].split("<br/>").join('\n');
            }
        });
        return el;
    })
}
export enum OrdersTableDataKeys {
    title = "title",
    photo = "photo",
    orderId = "orderId",
    asin = "asin",
    sku = "sku",
    date = "date",
    quantity = "quantity",
    fbaFee = "fbaFee",
}

interface ColumnOrders extends Column {
    accessor?: keyof typeof OrdersTableDataKeys;
}

function Orders() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentSearch, setCurrentSearch] = useState<string>(""); // todo
    const [salesAndTraffic, setSalesAndTraffic] = useState<SaleTraffic[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [returnedItems, setReturnedItems] = useState<ReturnItem[]>([]);
    const [fees, setFees] = useState<FbaFee[]>([]);
    let componentRef = useRef<TableComponentToPrint | null>(null);
    const [selectedPeriod, setSelectedPeriod] = useState<Period>("Today");

    const currency = useContext(CurrencyContext)?.currency;
    const convertCurrency = formatCurrency({ currency: currency?.currency, euroExchnageRate: currency?.exchangeRate })
    const convertCurrencySymbol = convertToCurrencyString(currency?.currency)

    useEffect(() => {
        setIsLoading(true);
        Promise.all([getOrders(), getFees(), getSalesAndTraffic(), getReturnReportData()]).then((val) => {
            setOrders(val[0]);
            setFees(val[1]);
            setSalesAndTraffic(val[2])
            setReturnedItems(val[3])
            setIsLoading(false);
        }).catch(error => {
            console.error(error);
            setIsLoading(false); // Ensure loading state is reset in case of an error
        });
    }, []);

    const [startDate, setStartDate] = useState<Date>(startOfDay(new Date())); // Default to start of today
    const [endDate, setEndDate] = useState<Date>(endOfDay(new Date())); // Default to end of today
    const handleDatesChange = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };
    const filteredDateOrders = getFilteredOrders(orders, startDate, endDate);
    const filteredOrders = getVisibleOrders(filteredDateOrders, fees);

    // TODO NOT CORRECT REPORT
    const tableData = groupeBySku(filteredOrders).map(el => {
        const { sku, asin, quantity, fbaFee, purchaseDate } = el[0];

        const convertedTime = new Date(purchaseDate);

        const [day, month, year] = [convertedTime.getDate().toString().padStart(2, '0'), (convertedTime.getMonth() + 1).toString().padStart(2, '0'), convertedTime.getFullYear()]

        return {
            title: "title",
            sku: sku,
            asin,
            date: `${day}-${month}-${year}`,
            quantity,
            fbaFee: convertCurrencySymbol(convertCurrency(fbaFee))
        }
    });

    const { allSelectedIds, toggleSelect } = useToggleSelect({ identificatorSelectors: tableData.map(el => el.sku) })

    const { t } = useTranslation();

    const { sideBar, actions, ordersTable } = TranslationPrefixes;

    const createTableCell = (accessor: OrdersTableDataKeys) => {
        return {
            accessor,
            cell: (val: any) => <Typography variant="body1">{val}</Typography>,
            header: t(`${ordersTable}${accessor}`)
        }
    }

    Object.values(OrdersTableDataKeys).map(createTableCell)

    const columns: Array<ColumnOrders> = [
        {
            cell: (el: any) => {
                return (
                    <Checkbox
                        checked={allSelectedIds.includes(el.sku)}
                        onChange={() => toggleSelect({ singleSelector: el.sku })
                        }
                    />
                )
            },
            header: "checkBox"
        },
        ...Object.values(OrdersTableDataKeys).map(createTableCell)
    ];
    const csvColumns = getDynamicColumnsForExport({ t, prefix: TranslationPrefixes.orders, values: OrdersTableDataKeys });

    const renderHeaderActions = () => {
        if (!allSelectedIds.length) return;
        const selectedData = tableData.filter((el) => allSelectedIds.includes(el.sku));
        const csvData = getCsvData(selectedData);
        const csvFileName = `orders_${+new Date()}.csv`;
        return (
            <CardHeaderActions
                csvHeaders={getColumns(csvColumns)}
                csvFileName={csvFileName}
                csvData={csvData}
                componentRef={componentRef}
            />
        );
    }

    return (
        <CardComponent title={t(`${sideBar}orders`)} isLoading={isLoading} headerActions={renderHeaderActions()}>
            <Box>
                <PeriodSelection onDatesChange={handleDatesChange}>
                    <Grid item xs={12} md={3}>
                        <FormControl style={{ width: "100%" }}>
                            <TextField
                                fullWidth
                                onChange={(e) => {
                                    setCurrentSearch(e.target.value as string);
                                }}
                                label={t(`${actions}search`)}
                            />
                        </FormControl>
                    </Grid>
                </PeriodSelection>
                <Box marginBottom="15px" marginTop="15px">
                    <Divider />
                </Box>
                {
                    tableData.length > 0 && (
                        <div style={{ display: "none" }}>
                            <TableComponentToPrint
                                ref={componentRef}
                                data={tableData}
                                headers={csvColumns}
                                title={t(`${sideBar}orders`)}
                            />
                        </div>
                    )
                }
                <TableComponent
                    data={tableData}
                    currentSearch={currentSearch}
                    columns={columns}
                    checkBox={{
                        checked: tableData.length === allSelectedIds.length,
                        onChangeCheckBox: (toggleAll) => {
                            toggleSelect({ toggle: toggleAll })
                        }
                    }} />
            </Box>
        </CardComponent>
    )
}

export default Orders;
