export function getTotalWords(phrases: string[]) {
    return phrases.join(" ").split(" ").filter(word => word !== "");
}

export function getTotalCharactersLength(phrases: string[]) {
    return phrases.reduce((prev, curr) => prev + curr.split(" ").join("").length, 0)
}

export function getWordsCounted(phrases: string[]) {
    const text = phrases.join(" ").split(" ");
    const map = new Map<string, { counter: number; firstOccurance: boolean }>();

    for (const word of text) {
        if (map.has(word)) {
            const wordsCounted = map.get(word);
            const counter = wordsCounted ? wordsCounted.counter + 1 : 1;
            map.set(word, {
                counter,
                firstOccurance: true,
            });
        } else {
            map.set(word, {
                counter: 1,
                firstOccurance: true,
            });
        }
    }

    return map;
}

export function areMapsEqual(map1: Map<string, number>, map2: Map<string, number>) {
    if (map1.size !== map2.size) {
        return false;
    }

    for (const [key, value] of map1) {
        if (!map2.has(key) || map2.get(key) !== value) {
            return false;
        }
    }

    return true;
}