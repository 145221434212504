export function daysBetweenDates(date?: string): number {
    if (!date) {
        return 0
    };
    const givenDate = new Date(date);
    const todayDate = new Date();

    const timeDifference = todayDate.getTime() - givenDate.getTime();

    const daysDifference = timeDifference / (1000 * 3600 * 24);

    return Math.round(daysDifference);
}

