import { AppBar, Card, CardContent, IconButton, makeStyles, Toolbar, Typography } from "@material-ui/core";
import PrintIcon from '@material-ui/icons/Print';
import React, { useRef } from "react";
import ReactToPrint from 'react-to-print';
import Loader from "./Loader";

interface Props {
	title: string;
	headerActions?: any;
	children?: JSX.Element;
	isLoading?: boolean;
	chartPrint?: boolean;
}

export default function CardComponent(props: Props) {
	const useStyles = makeStyles(() => ({
		container: {},
		title: {
			flexGrow: 1,
		},
		root: {
			minWidth: 250,
			position: "relative",
		},
	}));
	const classes = useStyles();
	const componentRef = useRef(null);

	return (
		<div>
			<AppBar position="static">
				<Toolbar>
					<Typography variant="h6" className={classes.title}>
						{props.title}
					</Typography>
					{props.headerActions && props.headerActions}
					{props.chartPrint && <ReactToPrint
						trigger={() => (
							<IconButton edge="end" color="inherit">
								<PrintIcon />
							</IconButton>
						)}
						content={() => componentRef.current}
					/>}
				</Toolbar>
			</AppBar>
			<Card className={classes.root} ref={componentRef}>
				<CardContent style={{ padding: 16 }}>
					{props.isLoading && <Loader relative />}
					{props.children}
				</CardContent>
			</Card>
		</div>
	);
}
