import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, Checkbox, Grid, IconButton, TableFooter, TablePagination } from '@material-ui/core';
import { TranslationPrefixes } from 'consts/translationPrefixes';
import { useTranslation } from 'react-i18next';
import { ColumnIventoryOrder, InventoryD } from 'components/Inventory';
import SwapVertIcon from '@material-ui/icons/SwapVert';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableHeadRoot: {
        fontWeight: 600,
    },
    container: {
        maxHeight: "calc(100vh - 210px)",
    },
    tableCellHeader: {
        display: "flex",
        alignItems: "center",
        "& button": {
            padding: 5
        }
    },
    p: {
        "& p": {
            "display": " -webkit-box",
            "max-width": "200px",
            "-webkit-line-clamp": 4,
            "-webkit-box-orient": "vertical",
            "overflow": "hidden",
        }
    }
});

export interface Column {
    header: string | "checkBox";
    accessor?: string;
    cell: (cell: any, item?: any, accessor?: string) => JSX.Element;
}

interface Props {
    data: Array<InventoryD>;
    columns: Array<ColumnIventoryOrder>;
    perPage?: number;
    rowElement?: (data: any, index: number) => JSX.Element;
    checkBox?: {
        onChangeCheckBox: (allChecked: boolean) => void;
        checked: boolean;
    }
}

export function TableInventory(props: Props) {
    const { columns, data, perPage = 5, rowElement = undefined, checkBox } = props;
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [allChecked, setAllChecked] = React.useState(false);

    const [sortCells, setSortCells] = React.useState<{ path: Partial<keyof InventoryD>, order: "asc" | "desc" } | null>(null);

    const onSortCells = (accessor: keyof InventoryD) => {
        if (!sortCells) {
            setSortCells({ path: accessor, order: "asc" })
        } else if (sortCells.order === "asc") {
            setSortCells({ path: accessor, order: "desc" });
        } else {
            setSortCells({ path: accessor, order: "asc" });
        }
    };

    const cellsSort: (keyof InventoryD)[] = [
        "availableUnits",
        "daysSupply",
        "avgDailySales",
        "reorderUnits",
        "inboundUnits",
        "unfulfillableUnits",
        "reservedUnits"
    ];

    useEffect(() => {
        if (typeof props.checkBox?.checked === "boolean") {
            setAllChecked(props.checkBox?.checked)
        }
    }, [props.checkBox?.checked]);

    const { t } = useTranslation();
    const [rowsPerPage, setRowsPerPage] = React.useState(perPage);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const { table, inventory } = TranslationPrefixes

    const getSortedData = () => {
        if (!sortCells) {
            return data;
        }

        const { path, order } = sortCells
        const sortedData = data.sort((a, b) => {
            const first = a[path];
            const second = b[path];
            if (typeof first === "number" && typeof second === "number") {
                if (order === "asc") {
                    return first - second;
                }

                return second - first
            }

            return 0;
        });

        return sortedData;
    }

    const getStyles = (data: any, accessor?: keyof InventoryD) => {
        if (!accessor) {
            return
        }
        if (accessor === "reorderStatus") {
            if (data === t(`${inventory}loading`)) {
                return { color: "red" }
            } else {
                return { color: "green" }
            }
        }

        return;
    }

    const sortedData = getSortedData();

    return (
        <TableContainer className={classes.container}>
            <Table className={`${classes.table}`} stickyHeader>
                <TableHead>
                    <TableRow>
                        {
                            columns.map(({ header, accessor }, index) =>
                                <TableCell component="th"
                                    style={{ verticalAlign: 'bottom' }}
                                    classes={{ root: classes.tableHeadRoot }}
                                    key={`${index}`} >
                                    <Box className={classes.tableCellHeader}>

                                        {header === "checkBox" ?
                                            data.length > 0 ?
                                                <Checkbox
                                                    checked={checkBox ? checkBox.checked : false}
                                                    onChange={() => {
                                                        setAllChecked(!allChecked);
                                                        if (checkBox) {
                                                            checkBox.onChangeCheckBox(!allChecked);
                                                        }
                                                    }}
                                                /> : ""

                                            :
                                            header
                                        }
                                        {
                                            accessor && cellsSort.includes(accessor) &&
                                            <IconButton onClick={() => onSortCells(accessor)}>
                                                <SwapVertIcon />
                                            </IconButton>
                                        }
                                    </Box>
                                </TableCell>)
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                            if (rowElement !== undefined) {
                                return rowElement(item, index);
                            }

                            return (
                                <TableRow key={index} >
                                    {
                                        columns.map((column, columnIndex) => {
                                            const data = column.accessor ? item[column.accessor] : item;

                                            return (
                                                <TableCell size='medium' component="td" scope="row" key={columnIndex} style={{ verticalAlign: 'top' }} className={`${classes.p}`}>
                                                    <div style={getStyles(data, column.accessor)}>
                                                        {column.cell(data, item, column.accessor)}
                                                    </div>
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={12}
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t(`${table}elementsTable`)}
                            nextIconButtonText={t(`${table}nextPage`)}
                            backIconButtonText={t(`${table}previousPage`)}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer >
    );
}
