import { Box, Checkbox, Divider, Grid, TextField, Typography } from "@material-ui/core";
import { convertToCurrencyString, formatCurrency } from "common/Utils/currency";
import { calculateROI, getFilteredOrders, getVisibleOrders, groupeBySku, netMarginItemExpenses } from "common/Utils/orders";
import { FbaFee, InventoryReport, Order, ReturnItem, SaleTraffic } from "common/types";
import PeriodSelection from "components/shared/PeriodSelection";
import { TranslationPrefixes } from "consts/translationPrefixes";
import { CurrencyContext } from "context/currency";
import { endOfDay, startOfDay } from "date-fns";
import useToggleSelect from "hooks/useToggleSelect";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFees, getInventoryListingData, getOrders, getReturnReportData, getSalesAndTraffic } from "services/service";
import { getColumns, getDynamicColumnsForExport } from "../../common/Utils/ActionUtils";
import { TableComponentToPrint } from "../ComponentToPrint/TableComponent";
import CardComponent from "../shared/CardComponent";
import { CardHeaderActions } from "../shared/CardHeaderActions";
import TableComponent, { Column } from "../shared/TableComponent";
const getCsvData = (data: any[]) => {
    return data.map(el => {
        Object.keys(el).forEach(key => {
            if (typeof el?.key === "string") {
                el[key] = el[key].split("<br/>").join('\n');
            }
        });
        return el;
    })
}

export enum PerformanceTable {
    sku = "sku",
    grossRevenue = "grossRevenue",
    expenses = "expenses",
    netProfit = "netProfit",
    netMargin = "netMargin",
    roi = "roi",
    refunds = "refunds",
    units = "units",
    pageViews = "pageViews",
    sessionUnits = "sessionUnits",
    sessionUnitPercentage = "sessionUnitPercentage",
}

interface ColumnOrders extends Column {
    accessor?: keyof typeof PerformanceTable;
}

interface TableCalculatedData {
    [PerformanceTable.netProfit]: number;
    [PerformanceTable.grossRevenue]: number;
    [PerformanceTable.units]: number;
    [PerformanceTable.expenses]: number;
    [PerformanceTable.roi]: number;
    [PerformanceTable.pageViews]: number;
    [PerformanceTable.sessionUnits]: number;
    [PerformanceTable.sessionUnitPercentage]: number;
    [PerformanceTable.refunds]: number;
}

function ProductsPerformance() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentSearch, setCurrentSearch] = useState<string>(""); // todo
    const [salesAndTraffic, setSalesAndTraffic] = useState<SaleTraffic[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [returnedItems, setReturnedItems] = useState<ReturnItem[]>([]);
    const [fees, setFees] = useState<FbaFee[]>([]);
    const [inventory, setInventory] = useState<InventoryReport[]>([]);
    const [startDate, setStartDate] = useState<Date>(startOfDay(new Date())); // Default to start of today
    const [endDate, setEndDate] = useState<Date>(endOfDay(new Date())); // Default to end of today
    const handleDatesChange = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };

    useEffect(() => {
        setIsLoading(true);
        Promise.all([getOrders(), getFees(), getSalesAndTraffic(), getReturnReportData(), getInventoryListingData()]).then((val) => {
            setOrders(val[0]);
            setFees(val[1]);
            setSalesAndTraffic(val[2])
            setReturnedItems(val[3])
            setInventory(val[4])
            setIsLoading(false);
        }).catch(error => {
            console.error(error);
            setIsLoading(false); // Ensure loading state is reset in case of an error
        });
    }, []);

    const { t } = useTranslation();
    const currency = useContext(CurrencyContext)?.currency;
    const convertCurrency = formatCurrency({ currency: currency?.currency, euroExchnageRate: currency?.exchangeRate })

    const convertCurrencySymbol = convertToCurrencyString(currency?.currency)
    const filteredDateOrders = getFilteredOrders(orders, startDate, endDate)
    const calculatedOrders = getVisibleOrders(filteredDateOrders, fees);

    const groupedOrdersBySKU = groupeBySku(calculatedOrders);

    const tableData = groupedOrdersBySKU.map(sameSKUorders => {
        const skuMatch = sameSKUorders[0].sku;
        const salesTrafficItems = salesAndTraffic?.filter(el => el.sku === skuMatch);
        const inventoryItem = inventory.find(el => el.sku === sameSKUorders[0].sku)

        let pageViews = 0;
        let sessions = 0;
        for (let i = 0; i < salesTrafficItems.length; i++) {
            const element = salesTrafficItems[i].trafficByAsin || {};
            pageViews += element.pageViews || 0;
            sessions += element.sessions || 0;
        }

        const returnedQuantity = returnedItems.filter(el => el.sku === sameSKUorders[0].sku)
            .reduce((total, product) => {
                if (new Date(product["return-date"]) > startDate) {
                    return total + product.quantity;
                }
                return total;
            }, 0);

        console.log("r", returnedQuantity)

        const calculations = sameSKUorders.reduce((acc, order) => {
            const expenses = order.fbaFee + order.adsCost;
            acc[PerformanceTable.expenses] += convertCurrency(expenses);
            acc[PerformanceTable.grossRevenue] += convertCurrency(order.revenue);
            acc[PerformanceTable.units] += inventoryItem?.quantity ?? 0;
            acc[PerformanceTable.netProfit] += convertCurrency(order.revenue - expenses);
            acc[PerformanceTable.pageViews] = pageViews ?? 0;
            acc[PerformanceTable.sessionUnits] = sessions ?? 0;
            acc[PerformanceTable.sessionUnitPercentage] = sessions !== 0 ? Math.ceil((pageViews / sessions) * 100) : 0;
            acc[PerformanceTable.refunds] = returnedQuantity * order.fbaFee; // todo ??
            return acc;
        }, {
            expenses: 0,
            quantity: 0,
            netProfit: 0,
            roi: 0,
            pageViews: 0,
            sessionUnits: 0,
            sessionUnitPercentage: 0,
            units: 0,
            grossRevenue: 0,
            refunds: 0
        } as TableCalculatedData);

        for (const key in calculations) {
            if (typeof calculations[key as keyof TableCalculatedData] === 'number') {
                calculations[key as keyof TableCalculatedData] = parseFloat((calculations[key as keyof TableCalculatedData]).toFixed(2));
            }
        }

        const netMargin = convertCurrency(+netMarginItemExpenses({ expenses: calculations[PerformanceTable.expenses], totalRevenue: calculations[PerformanceTable.grossRevenue] }));

        const { expenses, grossRevenue, netProfit } = calculations;
        return {
            ...calculations,
            sku: sameSKUorders[0].sku,
            asin: sameSKUorders[0].asin,
            netMargin: convertCurrencySymbol(netMargin),
            expenses: convertCurrencySymbol(expenses),
            grossRevenue: convertCurrencySymbol(grossRevenue),
            netProfit: convertCurrencySymbol(netProfit),
            roi: calculateROI(grossRevenue, expenses)
        };
    });


    const { allSelectedIds, toggleSelect } = useToggleSelect({ identificatorSelectors: tableData.map(el => el.sku) })

    const { sideBar, actions, productsPerformance } = TranslationPrefixes;

    const createTableCell = (accessor: PerformanceTable) => {
        return {
            accessor,
            cell: (val: any) => <Typography variant="body1">{val}</Typography>,
            header: t(`${productsPerformance}${accessor}`)
        }
    }

    const columns: Array<ColumnOrders> = [
        {
            cell: (el) => {
                return (
                    <Checkbox
                        checked={allSelectedIds.includes(el.sku)}
                        onChange={() => toggleSelect({ singleSelector: el.sku })}
                    />
                )
            },
            header: "checkBox"
        },
        ...Object.values(PerformanceTable).map(createTableCell)
    ];

    let componentRef = useRef<TableComponentToPrint | null>(null);
    const csvColumns = getDynamicColumnsForExport({ t, prefix: TranslationPrefixes.productsPerformance, values: PerformanceTable });

    const renderHeaderActions = () => {
        if (!allSelectedIds.length) return;

        const selectedData = tableData.filter((el) => allSelectedIds.includes(el.sku));
        const csvData = getCsvData(selectedData);
        const csvFileName = `working_dashboard_${+new Date()}.csv`;
        return (
            <CardHeaderActions
                csvHeaders={getColumns(csvColumns)}
                csvFileName={csvFileName}
                csvData={csvData}
                componentRef={componentRef}
            />
        );
    }

    return (
        <CardComponent title={t(`${sideBar}products`)} isLoading={isLoading} headerActions={renderHeaderActions()}>
            <Box>
                <PeriodSelection onDatesChange={handleDatesChange} >
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            onChange={(e) => {
                                setCurrentSearch(e.target.value as string);
                            }}
                            label={t(`${actions}search`)}
                        />
                    </Grid>
                </PeriodSelection>
                <Box marginBottom="15px" marginTop="15px">
                    <Divider />
                </Box>
                {
                    tableData.length > 0 && (
                        <div style={{ display: "none" }}>
                            <TableComponentToPrint
                                ref={componentRef}
                                data={tableData}
                                headers={csvColumns}
                                title={t(`${sideBar}products`)}
                            />
                        </div>
                    )
                }
                <TableComponent
                    data={tableData}
                    currentSearch={currentSearch}
                    columns={columns}
                    checkBox={{
                        checked: tableData.length === allSelectedIds.length,
                        onChangeCheckBox: (toggleAll) => {
                            toggleSelect({ toggle: toggleAll })
                        }
                    }}
                />
            </Box>
        </CardComponent>
    )
}

export default ProductsPerformance;
