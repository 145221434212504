import { GridSize } from "@material-ui/core";
import { getReportData } from "common/Utils/getReportData";
import { FbaFee, InventoryFBA, InventoryReport, Order, ReportType, ReturnItem } from "common/types";
import firebase, { base } from "../components/firebase";

interface FieldConfig {
	maxSymbols?: number;
	name: string;
	label: string;
	type: 'text' | 'number' | 'checkbox' | 'autocomplete' | 'date';
	required: boolean;
	options?: string[]; // For autocomplete
	size?: GridSize;
	validation?: (value: any) => string | undefined;
	display?: (formData: FormData | DynamicRow) => boolean; // Function to determine if the field should be displayed
	disabled?: boolean;
	value?: string;
	onChange?: () => void;
}
export interface DynamicRow {
	id: string;
	[key: string]: string | number | boolean;
}

export type FormData = {
	[K in FieldConfig['name']]?: string | number | boolean;
} & { dynamicRows?: DynamicRow[] };

export const store = (collectionName: string, data: FormData) => {
	return firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/" + collectionName).push(data);
}

export const storeSet = (collectionName: string, data: any) => {
	return firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/" + collectionName).set(data);
}

export const corsSet = (sku: any, prop: any, value: any) => {
	return firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/itemCosts/" + sku + "/" + prop).set(value);
}

export const setInventoryReorderDates = (sku: any, prop: any, value: any) => {
	if (!sku) {
		return;
	}
	return firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/inventory/" + sku + "/" + prop).set(value);
}

export const setDbNote = async (id: string, note: string): Promise<void> => {
	try {
		const ref = firebase.db.ref(base + "/databases/" + id + "/notes");
		await ref.set(note);
	} catch (e) {
		console.error(e);
		throw e;
	}
}

export const findAndUpdate = async (collectionName: string, id: string, data: FormData): Promise<void> => {
	try {
		const ref = firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/" + collectionName + "/" + id);
		await ref.update(data);
	} catch (e) {
		console.error(e);
		throw e;
	}
}

export const find = async (collectionName: string, id: string): Promise<any> => {
	const snapshot = await firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/" + collectionName + "/" + id).once("value");
	return snapshot.val();
}

export const updateAmazonReports = async (): Promise<any> => {
	const marketplace = "US";
	const reportTypes: ReportType[] = [
		// "GET_FLAT_FILE_OPEN_LISTINGS_DATA",
		// "GET_FLAT_FILE_ALL_ORDERS_DATA_BY_ORDER_DATE_GENERAL",
		// "GET_FBA_ESTIMATED_FBA_FEES_TXT_DATA",
		// "GET_SALES_AND_TRAFFIC_REPORT",
		// 'GET_FBA_FULFILLMENT_CUSTOMER_RETURNS_DATA',
		"GET_FBA_MYI_UNSUPPRESSED_INVENTORY_DATA"
	];

	try {
		const snapshot = await firebase.db.ref(`${base}`).once("value");
		const userIds = snapshot.val() ? Object.keys(snapshot.val()) : [];

		const fetchReportDataForUser = async (userId: string): Promise<void> => {
			const userRef = firebase.db.ref(`${base}/${userId}/amazonCredentials`);
			const amazonCredentials = (await userRef.once("value")).val();

			if (!amazonCredentials) {
				console.warn(`No Amazon credentials found for user ${userId}`);
				return;
			}

			console.log("fetching data..");
			const reportPromises = reportTypes.map(async (reportType) => {
				try {
					const data = await getReportData(reportType, marketplace, amazonCredentials);
					await firebase.db.ref(`${base}/${userId}/reports/${reportType}`).set(data);
					console.log(`Data fetched successfully for report type ${reportType}`, data);
				} catch (error) {
					console.error(`Error fetching data for report type ${reportType} for user ${userId}:`, error);
				}
			});

			await Promise.all(reportPromises);
		};
		const userPromises = userIds.map(fetchReportDataForUser);
		await Promise.all(userPromises);
		console.log("All reports fetched successfully");
	} catch (error) {
		console.error("Error in fetching reports:", error);
	}

}

export const getData = async (collectionName: string): Promise<Array<any>> => {
	const snapshot = await firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/" + collectionName).once("value");
	const items: Array<any> = [];
	snapshot.forEach((childSnapshot: any) => {
		const data = { id: childSnapshot.key, ...childSnapshot.val() };
		items.push(data);
	});
	return items || [];
}

export const getDataDB = async <T>(collectionName: string): Promise<T> => {
	const items = (await firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/" + collectionName).once("value")).val();
	return items;
}

export const getOrders = async (): Promise<Array<Order>> => {
	const reportType: ReportType = "GET_FLAT_FILE_ALL_ORDERS_DATA_BY_ORDER_DATE_GENERAL";
	const items = (await firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/reports/" + reportType).once("value")).val();
	return items || [];
}

export const getInventoryUnsupressedData = async (): Promise<Array<InventoryFBA>> => {
	const reportType: ReportType = "GET_FBA_MYI_UNSUPPRESSED_INVENTORY_DATA";
	const items = (await firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/reports/" + reportType).once("value")).val();
	return items || [];
}

export const getRetunededItems = async (): Promise<Array<any>> => {
	const reportType: ReportType = "GET_FBA_FULFILLMENT_CUSTOMER_RETURNS_DATA";
	const items = (await firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/reports/" + reportType).once("value")).val();
	return items || [];
}

export const getSalesAndTraffic = async () => {
	const reportType: ReportType = "GET_SALES_AND_TRAFFIC_REPORT";
	const items = (await firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/reports/" + reportType).once("value")).val();
	return items || [];
}

export const getInventoryListingData = async (): Promise<InventoryReport[]> => {
	const reportType: ReportType = "GET_FLAT_FILE_OPEN_LISTINGS_DATA";
	const items = (await firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/reports/" + reportType).once("value")).val();
	return items || [];
}

export const getReturnReportData = async (): Promise<ReturnItem[]> => {
	const reportType: ReportType = "GET_FBA_FULFILLMENT_CUSTOMER_RETURNS_DATA";
	const items = (await firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/reports/" + reportType).once("value")).val();
	return items || [];
}

export const getFees = async (): Promise<Array<FbaFee>> => {
	const reportType: ReportType = "GET_FBA_ESTIMATED_FBA_FEES_TXT_DATA";
	const items = (await firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/reports/" + reportType).once("value")).val();
	return items || [];
}

export const getSingleData = async (collectionName: string): Promise<Array<any>> => {
	const snapshot = await firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/" + collectionName).once("value");
	return snapshot.val();
}

export const deleteItem = async (collectionName: string, id: string): Promise<void> => {
	try {
		const ref = firebase.db.ref(base + "/" + firebase.getCurrentUser()?.uid + "/" + collectionName + "/" + id);
		await ref.remove();
	} catch (e) {
		console.error(e);
		throw e;
	}
}


