import {CircularProgress} from "@material-ui/core";
import React from "react";

type Props = {
	relative?: boolean;
}
export default function Loader(props: Props) {
	return (
		<div id="loader" className={props.relative ? "absolute" : ""}>
			<CircularProgress />
		</div>
	);
}
