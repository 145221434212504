import React, { useEffect, useState } from "react";
import {
    createStyles, Box, Grid, Theme, Typography, Button,
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
    RouteComponentProps, useHistory, useParams, withRouter,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import WordsTextField from "./WordsTextField";
import { getTotalCharactersLength, getTotalWords } from "./utils";
import { ManipulationFunctions, ManipulationOptions } from "./types";
import { toast } from "react-toastify";
import { RoutesUrl } from "../../common/consts/Routes";
import { findAndUpdate, getData, store } from "../../services/service";
import CardComponent from "../shared/CardComponent";
import { KeyWordsData } from "./KeyWordsList";
import { TranslationPrefixes } from "consts/translationPrefixes";

const styles = ({ palette }: Theme) => {
    return createStyles({
        leftSide: {
            flex: 1
        },
        rightSide: {
            display: "flex",
            flexDirection: "column",
            flex: 1,
            "& > *:first-child": {
                marginBottom: 10
            },
        },
        textField: {
            marginBottom: 10,
            padding: 10,
            width: "100%",
        },
        createdWords: {
            marginBottom: 20,
            padding: 10,
            height: "100%",
            border: "1px solid gray",
        },
        leftOptions: {},
        rightOptions: {},
        totals: {
            "& > *": {
                marginRight: 10
            },
            "& span": {
                color: palette.primary.main,
                fontSize: "1.2rem"
            }
        },
        buttonExportsContainer: {
            display: "flex",
            flexDirection: "column",
            "& > *": {
                marginBottom: 10,
                maxWidth: 200,
                width: "100%",
                background: palette.primary.main,
            }
        }
    });
};

function KeyWords(props: RouteComponentProps & WithStyles): JSX.Element {
    const { classes } = props;

    const { push } = useHistory();
    const { id } = useParams<{ id?: string }>();

    const [allOptions, setAllOptions] = useState<ManipulationOptions>({
        removeDuplicates: false,
        lowerCase: false,
        upperCase: false,
        allUpperCase: false,
        sortAlphabetically: false,
        singleLine: false,
    });
    const [totalPhrases, setTotalPhrases] = useState<string[]>([]);
    const [convertedPhrases, setConvertedPhrases] = useState<string[]>([]);
    const [totalCharacters, setTotalCharacters] = useState(0);
    const [sku, setSku] = useState("");
    const [isErrorSKU, setIsErrorSKU] = useState(false);
    const [data, setData] = useState<KeyWordsData[]>([]);

    const { t } = useTranslation();

    useEffect(() => {
        getData("keywords").then((data) => setData(data));
    }, []);

    useEffect(() => {
        if (id) {
            const item = data.find((el) => el.id === id);
            if (item) {
                const { sku, phrases, convertedPhrases } = item;
                setSku(sku);
                setTotalPhrases(phrases);
                setConvertedPhrases(convertedPhrases);
            }
        }
    }, [id, data]);

    const { keywords } = TranslationPrefixes;
    const handleTextFieldWords = (event: React.ChangeEvent<HTMLInputElement>) => {
        const words = event.currentTarget.value.split('\n').map(el => el.split(" ").filter(Boolean).join(" ")).filter(Boolean);
        const totalCharacters = getTotalCharactersLength(words);

        setTotalPhrases(words);
        setTotalCharacters(totalCharacters);
    }

    const handleSubmit = async () => {
        const data = {
            convertedPhrases,
            phrases: totalPhrases,
            sku
        };

        // console.log("Data", data)
        // return;

        try {
            if (id) {
                await findAndUpdate("keywords", id, data as any);
                toast.success(t(`${keywords}successfulUpdate`));
                push(RoutesUrl.keyWords.list);
                return;
            }
            await store("keywords", data as any);
            toast.success(t(`${keywords}successfulSaved`));
            push(RoutesUrl.keyWords.list);
            return true;
        } catch (e) {
            toast.error(t(`${keywords}error`));
            return false;
        }
    }

    const manipulationTextfieldInput = (args: string[]): ManipulationFunctions => {
        return {
            lowerCase: () => {
                return args.map(phrase => phrase.toLowerCase());
            },
            removeDuplicates: () => {
                const wordsSet = new Set<string>();

                return args.map(phrase =>
                    phrase.split(/\s+/).filter(word => {
                        if (!wordsSet.has(word)) {
                            wordsSet.add(word);
                            return true
                        }
                        return false
                    }).join(" ")
                );
            },
            sortAlphabetically: () => {
                return args.map(phrase => phrase.split(" ").sort((a, b) => a.localeCompare(b)).join(" "))
            },
            upperCase: () => {
                return args.map(phrase => {
                    const wordUpperCase = phrase.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1));
                    return wordUpperCase.join(" ")
                });
            },
            allUpperCase: () => {
                return args.map(phrase => phrase.toUpperCase());
            }
        }
    };

    const convertTextFieldInput = () => {
        let result = allOptions.singleLine ? totalPhrases : [totalPhrases.join(" ")];

        for (const [key, value] of Object.entries(allOptions)) {
            const keyAs = key as keyof ManipulationOptions;
            if (value && keyAs !== "singleLine") {
                result = manipulationTextfieldInput(result)[keyAs]();
            }
        }

        setConvertedPhrases(result);
    }

    const handleAllOptionsChange = (event: React.ChangeEvent<HTMLInputElement>, option: keyof ManipulationOptions) => {
        const { checked } = event.target
        setAllOptions({ ...allOptions, [option]: checked })
    }

    const renderSpanText = (text1: string, count: number) => (
        <Typography>{t(text1)}: <span>{count}</span></Typography>
    );

    const getIsErrorSKU = (skuValue: string) => {
        if (id) {
            const item = data.find((el) => el.id === id);

            if (item && item.sku === skuValue) {
                return false;
            }
        }

        return data.some((el) => el.sku === skuValue);
    }

    const handleChangeSKU = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSku(value);
        setIsErrorSKU(getIsErrorSKU(value));
    };

    return <CardComponent title={t(`${keywords}headline`)}>
        <Grid container>
            <WordsTextField
                editModeData={{ sku, phrases: totalPhrases }}
                onHandleChangeSKU={handleChangeSKU}
                onHandleTextFieldWords={handleTextFieldWords}
                onHandleAllOptionsChange={handleAllOptionsChange}
                onConvertTextFieldInput={convertTextFieldInput}
                isErrorSKU={isErrorSKU}
            >
                <Grid container justifyContent="flex-end" className={classes.totals}>
                    {renderSpanText(t(`${keywords}totalCharacters`), totalCharacters)}
                    {renderSpanText(t(`${keywords}totalWords`), getTotalWords(totalPhrases).length)}
                    {renderSpanText(t(`${keywords}totalPhrases`), totalPhrases.length)}
                </Grid>
            </WordsTextField>
            <Box className={classes.rightSide}>
                <Grid container justifyContent="flex-end" className={classes.totals}>
                    {renderSpanText(`${keywords}totalCharacters`, getTotalCharactersLength(convertedPhrases))}
                    {renderSpanText(`${keywords}totalWords`, getTotalWords(convertedPhrases).length)}
                    {renderSpanText(`${keywords}totalPhrases`, convertedPhrases.length)}
                </Grid>
                <Box className={classes.createdWords}>
                    {convertedPhrases.map((phrase, index) => (
                        <Typography key={phrase + index}>{phrase}</Typography>
                    ))}
                </Box>
                <Button variant="contained" color="primary" disabled={!convertedPhrases.length || isErrorSKU} onClick={handleSubmit} type="submit">{t(`${keywords}save`)}</Button>
            </Box>
        </Grid >
    </CardComponent >

}

export default withRouter(withStyles(styles)(KeyWords));
