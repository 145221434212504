import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
export const base = "tpls";
const config = {
	apiKey: "AIzaSyCl5p31sriN8tbyyEKBxCETrVg33CyDEI0",
	authDomain: "crm-4aa49.firebaseapp.com",
	projectId: "crm-4aa49",
	storageBucket: "crm-4aa49.appspot.com",
	messagingSenderId: "423872025791",
	appId: "1:423872025791:web:2cbd51fffebd2a1b95620a"
};

class Firebase {
	private static instance: Firebase;
	auth: app.auth.Auth;

	db: app.database.Database;

	private constructor() {
		app.initializeApp(config);
		this.auth = app.auth();
		this.db = app.database();
	}

	/**
	 * The static method that controls the access to the singleton instance.
	 *
	 * This implementation let you subclass the Singleton class while keeping
	 * just one instance of each subclass around.
	 */
	public static getInstance(): Firebase {
		if (!Firebase.instance) {
			Firebase.instance = new Firebase();
		}

		return Firebase.instance;
	}


	login(email: string, password: string) {
		return this.auth.signInWithEmailAndPassword(email, password);
	}

	logout() {
		return this.auth.signOut();
	}

	async register(name: string, email: string, password: string) {
		await this.auth.createUserWithEmailAndPassword(email, password);
		// eslint-disable-next-line no-unused-expressions
		this.auth.currentUser?.sendEmailVerification();
		return this?.auth?.currentUser?.updateProfile({
			displayName: name,
		});
	}

	async resetPassword(email: string) {
		return this.auth.sendPasswordResetEmail(email);
	}

	addQuote(quote: string) {
		if (!this.auth.currentUser) {
			// eslint-disable-next-line no-alert
			return alert("Not authorized");
		}

		return this.db.ref(`users/${this.auth.currentUser.uid}`).set({
			quote,
		});
	}

	isInitialized() {
		return new Promise((resolve) => {
			this.auth.onAuthStateChanged(resolve);
		});
	}

	getCurrentUser() {
		return this.auth.currentUser;
	}

	isUserVerified() {
		return this.auth.currentUser && this.auth.currentUser.emailVerified;
	}

	async getCurrentUserQuote() {
		const snapshot = await this.db.ref(`users/${this.auth.currentUser?.uid}`).once("value");
		return snapshot.val()?.quote;
	}


	public database() {
		return app.database;
	}
}

export default Firebase.getInstance();
