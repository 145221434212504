import { Box, Grid, Typography, createStyles } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React, { ComponentProps } from "react";
import logoFooter from "../../assets/erasmus.png";
import logo from "../../assets/logo.png";
import parner1 from "../../assets/parner1.png";
import parner2 from "../../assets/parner2.png";

const styles = () => {
	return createStyles({
		"@global": {
			"#root": {
				padding: "1px",
			},
			body: {
				backgroundSize: "cover",
				backgroundPosition: "bottom center",
			},
			"body, html": {
				padding: 0,
				margin: 0,
				height: "100%",
				width: "100%",
			},
		},
		container: {
			width: "750px",
			margin: "100px auto 40px auto",
			textAlign: "center",
			maxWidth: "calc(100% - 40px)",
			paddingTop: "1px",
			paddingBottom: "1px"
		},
		logo: {
			width: "200px",
			display: "block",
			margin: "0 auto 25px auto",
		},
	});
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function SignIn(props: ComponentProps<"body"> & WithStyles): JSX.Element {
	const { classes, children } = props;
	return (
		<Box className={classes.container}>
			<img src={logo} alt="logo" className={classes.logo} />
			<Box marginBottom={6}><Typography variant="h6"><strong>Learning software module for SEO analysis <br />
				and digital content for e-commerce</strong></Typography></Box>
			<Box sx={{ width: "400px", maxWidth: "100%", marginLeft: "auto", marginRight: "auto", mb: 7 }}>
				{children}
			</Box>
			<Box marginBottom={6}>
				<Grid container spacing={2} alignItems="center" justifyContent="center" >
					<img src={logoFooter} height="60px" width="auto" style={{ marginRight: "30px" }}></img>
					<Typography align="left" variant="body1">

						Agreement No: 2023-1-BG01-KA210-VET-000165566 <br />
						Key Action 2, Strategic Partnerships in VET Education <br />
						Under Erasmus+ Programme
					</Typography>
				</Grid>
			</Box>
			<Box marginBottom={6}>
				<Grid container spacing={2} alignItems="center" justifyContent="center" >
					<Typography align="left" variant="body1" style={{ marginRight: "30px" }}>
						Partners:
					</Typography>
					<img src={parner1} height="60px" width="auto" style={{ marginRight: "30px" }}></img>
					<img src={parner2} height="60px" width="auto" style={{ marginRight: "30px" }}></img>
				</Grid>
			</Box>

		</Box >
	);
}

export default withStyles(styles)(SignIn);
