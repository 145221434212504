import { useState } from "react";

interface Props {
    identificatorSelectors: string[];

}

const useToggleSelect = (props: Props) => {
    const { identificatorSelectors } = props;
    const [allSelectedIds, setAllSelectedIds] = useState<string[]>([]);

    const toggleSelect = (args: { toggle?: boolean, singleSelector?: string }) => {
        const { singleSelector, toggle } = args;
        if (typeof toggle === "boolean") {
            if (toggle) {
                setAllSelectedIds(identificatorSelectors)
            } else {
                setAllSelectedIds([])
            }
        } else if (typeof singleSelector === "string") {
            const exists = allSelectedIds.includes(singleSelector);
            if (exists) {
                const index = allSelectedIds.indexOf(singleSelector);
                const newSelectedIds = [...allSelectedIds.slice(0, index), ...allSelectedIds.slice(index + 1)];
                setAllSelectedIds(newSelectedIds)
            } else {
                setAllSelectedIds([...allSelectedIds, singleSelector]);
            }
        }
    }

    return { allSelectedIds, toggleSelect };
};

export default useToggleSelect;