import { Button, ButtonGroup, } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import PrintIcon from "@material-ui/icons/Print";
import TextIcon from "@material-ui/icons/TextFormat";
import React from "react";
import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import { TableComponentToPrint } from "../ComponentToPrint/TableComponent";

type Props = {
	csvHeaders: Array<any>;
	csvFileName: string;
	csvData: Array<any>
	componentRef: React.MutableRefObject<TableComponentToPrint | null>;
	onDownloadTextFile?: () => void;
}

export function CardHeaderActions(props: Props) {
	const { csvHeaders: headers, componentRef, csvData, csvFileName, onDownloadTextFile } = props;

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<ButtonGroup variant="contained" color="secondary" >
			<Button>
				<CSVLink headers={headers} style={{ color: "inherit", height: "24px" }}
					data={csvData}
					filename={csvFileName}>
					<DescriptionIcon />
				</CSVLink>
			</Button>
			<Button onClick={() => handlePrint ? handlePrint() : null}>
				<PrintIcon />
			</Button>
			{
				onDownloadTextFile &&
				<Button onClick={() => onDownloadTextFile()}>
					<TextIcon />
				</Button>
			}
		</ButtonGroup>
	)
}
