import { CogsItem, FbaFee, InventoryReport, Order, VisibleOrder, VisibleOrderMetric } from "common/types";
import { ChartData } from "components/DashboardOrders/OrderChart";
import { differenceInCalendarMonths, startOfYear } from "date-fns";

const getMonthsPassedInCurrentYear = () => {
  const now = new Date();
  const startOfCurrentYear = startOfYear(now);
  return differenceInCalendarMonths(now, startOfCurrentYear);
}

export function getVisibleOrders(orders: Order[], fees: FbaFee[]): VisibleOrder[] {
  return orders?.map(order => {
    const fee = fees.find(fee => fee.sku === order.sku);
    const revenue = (order.quantity * (order['item-price']) || 0);
    // Mexican pesos to USD conversion
    const fbaFee = (fee?.["estimated-fee-total"] || 0) * 0.060;
    const adsCost = revenue * 0.1736;
    const initNetProfit = revenue - fbaFee - adsCost;
    const netProfit = initNetProfit > 0 ? initNetProfit : 0;
    const netMargin = revenue > 0 ? (netProfit / revenue) * 100 : 0; // Ensure no division by zero
    const soldItemsCost = fbaFee + adsCost;
    return {
      asin: order.asin,
      sku: order.sku,
      quantity: order.quantity,
      revenue,
      fbaFee,
      adsCost,
      netProfit,
      purchaseDate: order['purchase-date'],
      soldItemsCost,
      itemPrice: order?.["item-price"] || 0,
      netMargin
    };
  });
}

export const getCogsItems = (args: { fees: FbaFee[], inventory: InventoryReport[] }): CogsItem[] => {
  const { inventory, fees } = args;
  const groupedBySku = {} as { [key: string]: CogsItem };

  // COGS = Amazon Fee + EXW Price + Customs + Transportation cost + Other cost
  inventory.forEach(item => {
    if (!groupedBySku[item.sku]) {
      const fee = fees?.find(el => el.sku === item.sku);

      const fbaFee = (fee?.["estimated-fee-total"] || 0) * 0.060
      groupedBySku[item.sku] = {
        asin: item.asin,
        fbaFee: Number(fbaFee.toFixed(2)),
        productPrice: item.price,
        sku: item.sku
      };
    }
  });

  return Object.values(groupedBySku);
};

export const groupeBySku = (calculatedOrders: VisibleOrder[]) => {
  const groupedOrdersBySku = calculatedOrders.reduce((acc, order) => {
    if (!acc[order.sku]) {
      acc[order.sku] = [];
    }
    acc[order.sku].push(order);
    return acc;
  }, {} as { [key: string]: VisibleOrder[] }
  )

  return Object.values(groupedOrdersBySku);
}

export const calculateROI = (totalRevenue: number, expenses: number) => {
  if (totalRevenue === 0) {
    return 0
  }

  return +(((totalRevenue - expenses) / totalRevenue) * 100).toFixed(2)

}
export const netMarginItemExpenses = (args: { totalRevenue: number, expenses: number }) => {
  const { expenses, totalRevenue } = args;
  return (((totalRevenue - expenses) / totalRevenue) * 100).toFixed(2)
}
export const netMarginItem = (args: { totalRevenue: number, fbaFee: number, adsCost: number }) => {
  const { totalRevenue, adsCost, fbaFee } = args;

  if (totalRevenue === 0) {
    return 0
  }

  return (((totalRevenue - fbaFee - adsCost) / totalRevenue) * 100).toFixed(2)
}
export const netMargin = (orders: VisibleOrder[]): number => orders.reduce((total, order) => total + order.netProfit, 0) / orders.reduce((total, order) => total + order.revenue, 0) * 100;
export const businessEvaluation = (orders: VisibleOrder[]): number => (orders.reduce((total, order) => total + order.revenue, 0) / getMonthsPassedInCurrentYear()) * 5;
// in percentage
export const ACOS = 17.36;

export const getFilteredOrders = (orders: Order[], startDate: Date, endDate: Date): Order[] => {
  return orders.filter(order => {
    const orderDate = new Date(Date.parse(order['purchase-date']));
    return orderDate >= startDate && orderDate <= endDate;
  }).sort((a, b) => (new Date(a['purchase-date']) as unknown as number) - (new Date(b['purchase-date']) as unknown as number));
};

export const calculateTotal = (orders: VisibleOrder[], metric: VisibleOrderMetric): number => {
  if (metric === 'netMargin') {
    const totalRevenue = orders.reduce((total, order) => total + order.revenue, 0);
    const totalNetProfit = orders.reduce((total, order) => total + order.netProfit, 0);
    return totalRevenue > 0 ? (totalNetProfit / totalRevenue) * 100 : 0;
  } else {
    return orders.reduce((total, order) => total + order[metric], 0);
  }
};

export function aggregatedOrdersChartData(purchases: VisibleOrder[], metric: VisibleOrderMetric): ChartData[] {
  const aggregatedData: Record<string, { date: string; sumOfTotalRevenue: number; totalRevenue: number; totalNetProfit: number }> = {};
purchases = purchases.filter(purchase => purchase?.soldItemsCost > 0);
  // Iterate through each purchase
  for (const purchase of purchases) {
    // Extract date from purchase
    const date = purchase.purchaseDate.split('T')[0];
    // If date does not exist in aggregatedData, initialize it
    if (!aggregatedData[date]) {
      aggregatedData[date] = {
        date,
        sumOfTotalRevenue: 0,
        totalRevenue: 0,
        totalNetProfit: 0
      };
    }
    // Add metric value to aggregatedData for corresponding date
    aggregatedData[date].sumOfTotalRevenue += purchase?.[metric] || 0;
    aggregatedData[date].totalRevenue += purchase.revenue;
    aggregatedData[date].totalNetProfit += purchase.netProfit;
  }

  // Convert aggregatedData into an array of ChartData objects
  const result: ChartData[] = Object.values(aggregatedData).map(data => ({
    date: data.date,
    sumOfTotalRevenue: metric === 'netMargin' && data.totalRevenue > 0 
      ? (data.totalNetProfit / data.totalRevenue) * 100 
      : data.sumOfTotalRevenue
  }));

  return result;
}
