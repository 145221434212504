export enum TranslationPrefixes {
    sideBar = "sideBar.",
    keywords = "modules.keyWords.",
    errors = "errors.",
    keyWordsList = "modules.keyWordsList.",
    deletion = "deletion.",
    ads = "modules.ads.",
    workingDashboard = "modules.workingDashboard.",
    adsFields = "modules.ads.fields.",
    form = "modules.form.",
    actions = "actions.",
    htmlEditor = "modules.htmlEditor.",
    accountMenu = "modules.accountMenu.",
    credentialsForm = "modules.credentialsForm.",
    productsPerformance = "modules.productsPerformance.",
    profitBreakdown = "modules.profitBreakdown.",
    orders = "modules.ordersTable.",
    cogs = "modules.cogs.",
    ordersTable = "modules.ordersTable.",
    table = "modules.table.",
    footer = "footer.",
    inventory = "modules.inventory."
}