export const RoutesParams = {
	id: 'id',
	state: 'state',
};

const getParam = (param: string) => `:${param}`;

export const RoutesUrl = {
	keyWords: {
		list: "/dashboard/keywords/list",
		create: "/dashboard/keywords/create",
		edit: (id?: string) => `/dashboard/keywords/edit/${id || getParam(RoutesParams.id)}`,
	},
	salesVisualisation: {
		totalRevenue: "/dashboard/sales-visualisation/total-revenue",
		netProfit: "/dashboard/sales-visualisation/net-profit",
	},
	dashboard: {
		orders: "/dashboard/orders",
		reports: "/dashboard/fetch-reports",
		productsProfit: "/dashboard/products-profit",
		products: "/dashboard/products",
		cogs: "/dashboard/cogs",
		inventory: "/dashboard/inventory",
		profitBreakdown: "/dashboard/profit-breakdown"
	},
	orders: "/orders",
	ads: {
		list: "/dashboard/ads/list",
		create: "/dashboard/ads/create",
		edit: (id?: string) => `/dashboard/ads/edit/${id || getParam(RoutesParams.id)}`,
	},
	reports: {
		inventory: "/business-reports/inventory",
		orders: "/business-reports/orders",
		salesVisualisation: "/business-reports/sales-visualisation/net-profit"
	},
	editor: "/dashboard/editor",
	credentialsForm: "/credentialsForm"
};
