export const getFilteredSearchedData = ({
    currentSearch,
    tableData,
    searchKeys = ["sku", "asin"]
  }: {
    currentSearch: string,
    tableData: object[],
    searchKeys?: string[]
  }) => {
    return tableData.filter((el) => {
      // Check if any of the searchKeys contain the currentSearch string
      return searchKeys.some((key) => {
        const value = (el as any)[key]; // Access the property dynamically
        if (typeof value === 'string') {
          return value.toLowerCase().includes(currentSearch.toLowerCase());
        }
        return false; // Handle non-string values if needed
      });
    });
  }
  