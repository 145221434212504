import { Box, Checkbox, Divider, FormControl, Grid, TextField, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import { downloadText } from "common/Utils/downloadDocuments";
import { TranslationPrefixes } from "consts/translationPrefixes";
import useToggleSelect from "hooks/useToggleSelect";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getColumns, getKeyWordsColumnsForExport } from "../../common/Utils/ActionUtils";
import { RoutesUrl } from "../../common/consts/Routes";
import { deleteItem, getData } from "../../services/service";
import AlertModal from "../shared/AlertModal";
import CardComponent from "../shared/CardComponent";
import { CardHeaderActions } from "../shared/CardHeaderActions";
import TableComponent, { Column } from "../shared/TableComponent";
import KeyWordsPrint from "./KeyWordsPrint";

const getCsvData = (data: any[]) => {
    return data.map(el => {
        Object.keys(el).forEach(key => {
            if (typeof el?.key === "string") {
                el[key] = el[key].split("<br/>").join('\n');
            }
        });
        return el;
    })
}

export interface KeyWordsData {
    id: string;
    sku: string;
    phrases: string[];
    convertedPhrases: string[];
};

function KeywordsList() {
    const [data, setData] = useState<Array<KeyWordsData>>([]);
    const [itemToDelete, setItemToDelete] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openedModal, setOpenedModal] = useState<boolean>(false);
    const [currentSearch, setCurrentSearch] = useState<string>("");

    const handleDelete = (dataBases: KeyWordsData) => {
        setItemToDelete(dataBases);
        setOpenedModal(true);
    }
    const { t } = useTranslation();

    const getFilteredSearchedData = () => {
        if (currentSearch === "") {
            return data;
        }

        return data.filter((el) => {
            const convertedPhrases = el.convertedPhrases.join(" ");
            const phrases = el.phrases.join(" ");

            const doesInclude = el.sku.includes(currentSearch) || convertedPhrases.includes(currentSearch) || phrases.includes(currentSearch);

            return doesInclude;
        });
    }
    const filteredSearchData = getFilteredSearchedData();

    const { allSelectedIds, toggleSelect } = useToggleSelect({ identificatorSelectors: filteredSearchData.map(el => el.id) })

    const { deletion, keyWordsList } = TranslationPrefixes;

    const handleModalSuccess = async () => {
        if (!itemToDelete?.id) {
            return
        }

        const { id, sku } = itemToDelete;

        try {
            await deleteItem("keywords", id);
            toast.success(t(`${deletion}success`) + ` - ${sku}`);
            const newData = data.filter((el) => el.id !== id);
            setData(newData);
        } catch (e) {
            toast.error(t(`${deletion}error`) + ` - ${sku}`);
        }

        setItemToDelete(null);
        setOpenedModal(false);
    }
    const closeModal = () => {
        setItemToDelete(null);
        setOpenedModal(false);
    }

    useEffect(() => {
        setIsLoading(true);
        getData("keywords")
            .then((data) => {
                setData(data)
            })
            .finally(() => setIsLoading(false));
    }, []);

    const columns: Array<Column> = [
        {
            cell: (el) => <Checkbox
                checked={allSelectedIds.includes(el.id)}
                onChange={() => toggleSelect({ singleSelector: el.id })}
            />,
            header: "checkBox"
        },
        {
            accessor: "sku",
            cell: (val) => <Typography variant="body1">{val}</Typography>,
            header: t(`${keyWordsList}skuCatalog`)
        },
        {
            accessor: "phrases",
            cell: (val: string[]) => {
                return <Typography variant="body1">{val.join(" ")}</Typography>
            },
            header: t(`${keyWordsList}phrases`)
        },
        {
            accessor: "convertedPhrases",
            cell: (val: string[]) => <Typography variant="body1">{val.join(" ")}</Typography>,
            header: t(`${keyWordsList}convertedPhrases`)
        },
        {
            cell: (dataBases: KeyWordsData) => {
                return (
                    <Box display="flex">
                        <Link to={`${RoutesUrl.keyWords.edit(dataBases.id)}`}>
                            <Typography color="primary" title={t(`${keyWordsList}edit`)}><EditIcon /></Typography>
                        </Link>
                        <Typography color="primary" title={t(`${keyWordsList}delete`)} onClick={() => handleDelete(dataBases)} style={{ cursor: "pointer" }}>
                            <DeleteIcon />
                        </Typography>
                    </Box>
                )
            },
            header: t(`${keyWordsList}action`)
        }
    ];

    let componentRef = useRef(null);

    const csvColumns = getKeyWordsColumnsForExport(t);

    const convertDataForTextFile = () => {
        const selectedData = data.filter((el) => allSelectedIds.includes(el.id));

        return selectedData.map((el) => {
            return `${t(`${keyWordsList}skuCatalog`)}:\n${el.sku}\n\n${t(`${keyWordsList}phrases`)}: \n${el.phrases.join("\n")}\n\n${t(`${keyWordsList}convertedPhrases`)}: \n${el.convertedPhrases.join("\n")}\n\n\n\n`
        });
    }

    const handleDownloadTextFile = () => {
        const fileName = `keywords_${+new Date()}`;
        downloadText(convertDataForTextFile(), fileName);
    }

    const renderHeaderActions = () => {
        if (!allSelectedIds.length) return;
        const selectedData = data.filter((el) => allSelectedIds.includes(el.id));
        const csvData = getCsvData(selectedData);
        const fileName = `keywords_${+new Date()}.csv`;
        return (
            <CardHeaderActions
                csvHeaders={getColumns(csvColumns)}
                csvFileName={fileName}
                csvData={csvData}
                componentRef={componentRef}
                onDownloadTextFile={handleDownloadTextFile}
            />
        );
    }

    const getSelectedData = () => {
        return data.filter((el) => allSelectedIds.includes(el.id));
    }

    return (
        <CardComponent title={t(`${keyWordsList}headline`)} isLoading={isLoading} headerActions={renderHeaderActions()}>
            <Box>
                <AlertModal onClose={closeModal} onSuccess={handleModalSuccess} show={openedModal} title={t(`${keyWordsList}delete`)} >
                    <Typography>{t(`${keyWordsList}deleteQuestion`)}{itemToDelete?.name || ""} ?</Typography>
                </AlertModal>
                <Grid container style={{ flexGrow: 1 }} spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <FormControl style={{ width: "100%" }}>
                            <TextField
                                fullWidth
                                onChange={(e) => setCurrentSearch(e.target.value as string)}
                                label={t(`${keyWordsList}search`)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {
                    data.length > 0 && (
                        <div style={{ display: "none" }} >
                            <KeyWordsPrint
                                ref={componentRef}
                                data={getSelectedData()}
                                headers={csvColumns}
                                title={t(`${keyWordsList}headline`)}
                            />
                        </div>
                    )
                }
                <Box marginBottom="15px" marginTop="15px">
                    <Divider />
                </Box>
                <TableComponent
                    data={data}
                    currentSearch={currentSearch}
                    searchKeys={["sku", "phrases", "convertedPhrases"]}
                    columns={columns}
                    checkBox={{
                        checked: data.length === allSelectedIds.length,
                        onChangeCheckBox: (toggleAll) => {
                            toggleSelect({ toggle: toggleAll })
                        }
                    }}
                />
            </Box>
        </CardComponent>
    )
}


export default KeywordsList;
