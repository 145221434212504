import { Box, Checkbox, Divider, FormControl, Grid, TextField, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import { downloadText } from "common/Utils/downloadDocuments";
import { TranslationPrefixes } from "consts/translationPrefixes";
import useToggleSelect from "hooks/useToggleSelect";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getAdsColumnsForExport, getColumns } from "../../common/Utils/ActionUtils";
import { RoutesUrl } from "../../common/consts/Routes";
import { deleteItem, getData } from "../../services/service";
import { TableComponentToPrint } from "../ComponentToPrint/TableComponent";
import AlertModal from "../shared/AlertModal";
import CardComponent from "../shared/CardComponent";
import { CardHeaderActions } from "../shared/CardHeaderActions";
import TableComponent, { Column } from "../shared/TableComponent";
const formatData = (data: any[]) => {
    return data.map(el => {
        Object.keys(el).forEach(key => {
            el[key] = el[key];
        });
        return el;
    })
}

const getCsvData = (data: any[]) => {
    return data.map(el => {
        Object.keys(el).forEach(key => {
            if (typeof el?.key === "string") {
                el[key] = el[key].split("<br/>").join('\n');
            }
        });
        return el;
    })
}

function List() {
    const { t } = useTranslation();
    const { adsFields, actions } = TranslationPrefixes;

    const [data, setData] = useState<Array<any>>([]);
    const [selected, setSelected] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openedModal, setOpenedModal] = useState<boolean>(false);
    const [currentSearch, setCurrentSearch] = useState<string>("");


    const { allSelectedIds, toggleSelect } = useToggleSelect({ identificatorSelectors: data.map(el => el.sku) })

    const selectedData = data.filter(el => allSelectedIds.includes(el.sku));



    const handleDelete = (dataBases: any) => {
        setSelected(dataBases);
        setOpenedModal(true);
    }

    const handleModalSuccess = async () => {
        if (!selected || !selected.id) return;
        const { id } = selected;
        try {
            await deleteItem("ads", id);
            toast.success(`${t(`${actions}successfulDeleteOf`)} ${selected?.name}`);
            const newData = data.filter((proc) => proc.id !== id);
            setData(newData);
        } catch (e) {
            toast.error(`${t(`${actions}errorDelete`)} ${selected.name}`);
        }

        setSelected(null);
        setOpenedModal(false);
    }

    const closeModal = () => {
        setSelected(null);
        setOpenedModal(false);
    }

    useEffect(() => {
        setIsLoading(true);
        getData("ads").then((values) => {
            setData(formatData(values));
        }).finally(() => setIsLoading(false));
    }, [])


    const columns: Array<Column> = [
        {
            cell: (el) => {
                return (
                    <Checkbox
                        checked={allSelectedIds.includes(el.sku)}
                        onChange={() => toggleSelect({ singleSelector: el.sku })}
                    />
                )
            },
            header: "checkBox"
        },
        {
            accessor: "title",
            cell: (val) => {
                return (
                    <Typography variant="body1">{val}</Typography>
                )
            },
            header: t(`${adsFields}title`)
        },
        {
            accessor: "description1",
            cell: (val) => {
                return (
                    <Typography variant="body1">{val}</Typography>
                )
            },
            header: t(`${adsFields}product1`)
        },
        {
            accessor: "description2",
            cell: (val) => {
                return (
                    <Typography variant="body1">{val}</Typography>
                )
            },
            header: t(`${adsFields}product2`)
        },
        {
            accessor: "description3",
            cell: (val) => {
                return (
                    <Typography variant="body1">{val}</Typography>
                )
            },
            header: t(`${adsFields}product3`)
        },
        {
            accessor: "adDescription",
            cell: (val) => {
                return (
                    <Typography variant="body1">{val}</Typography>
                )
            },
            header: t(`${adsFields}description`)
        },
        {
            accessor: "keywords",
            cell: (val) => {
                return (
                    <Typography variant="body1">{val}</Typography>
                )
            },
            header: t(`${adsFields}keywords`)
        },
        {
            accessor: "sku",
            cell: (val) => {
                return (
                    <Typography variant="body1">{val}</Typography>
                )
            },
            header: t(`${adsFields}sku`)
        },
        {
            cell: (dataBases) => {
                return (
                    <Box display="flex">
                        <Link to={`${RoutesUrl.ads.edit(dataBases.id)}`}>
                            <Typography color="primary" title={t(`${actions}edit`)}><EditIcon /></Typography>
                        </Link>
                        <Typography color="primary" title={t(`${actions}delete`)} onClick={() => handleDelete(dataBases)} style={{ cursor: "pointer" }}>
                            <DeleteIcon />
                        </Typography>
                    </Box>
                )
            },
            header: t(`${actions}action`)
        },
    ];

    let componentRef = useRef<TableComponentToPrint | null>(null);

    const csvColumns = getAdsColumnsForExport();

    const convertDataForTextFile = () => {
        return selectedData.map((el: any) => {
            return `${t(`${adsFields}title`)}:\n${el.title || ""}\n${t(`${adsFields}product1`)}: \n${el.description1 || ""}\n${t(`${adsFields}product2`)}: \n${el.description2 || ""}\n${t(`${adsFields}product3`)}: \n${el.description3 || ""}\n${t(`${adsFields}product4`)}: \n${el.description4 || ""}\n${t(`${adsFields}product5`)}: \n${el.description5 || ""}\n${t(`${adsFields}description`)}: \n${el.adDescription || ""}\n${t(`${adsFields}keywords`)}:\n${el.keywords || ""}\n${t(`${adsFields}sku`)}:\n${el.sku || ""}\n\n\n\n`
        });
    }
    const handleDownloadTextFile = () => {
        const fileName = `ads_${+new Date()}`;
        downloadText(convertDataForTextFile(), fileName);
    }

    const renderHeaderActions = () => {
        if (!selectedData.length) return;
        const csvData = getCsvData(selectedData);
        const csvFileName = `Ads_${+new Date()}.csv`;
        return (
            <CardHeaderActions
                csvHeaders={getColumns(csvColumns)}
                csvFileName={csvFileName}
                csvData={csvData}
                componentRef={componentRef}
                onDownloadTextFile={handleDownloadTextFile}
            />
        );
    }

    return (
        <CardComponent title={t(`${adsFields}list`)} isLoading={isLoading} headerActions={renderHeaderActions()}>
            <Box>
                <AlertModal onClose={closeModal} onSuccess={handleModalSuccess} show={openedModal} title={t(`${actions}delete`)} >
                    <Typography>{t(`${actions}questionDeleteOf`)} {selected ? selected.title : ""} ?</Typography>
                </AlertModal>
                <Grid container style={{ flexGrow: 1 }} spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                            <TextField
                                fullWidth
                                onChange={(e) => {
                                    setCurrentSearch(e.target.value as string);
                                }}
                                label={t(`${actions}search`)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {
                    data.length > 0 && (
                        <div
                            style={{ display: "none" }}
                        >
                            <TableComponentToPrint
                                ref={componentRef}
                                data={selectedData}
                                headers={csvColumns}
                                title={t(`${adsFields}list`)}
                            />
                        </div>
                    )
                }
                <Box marginBottom="15px" marginTop="15px">
                    <Divider />
                </Box>
                <TableComponent
                    data={data}
                    currentSearch={currentSearch}
                    searchKeys={["title",
                        "description1",
                        "description2",
                        "description3",
                        "description4",
                        "description5",
                        "description",
                        "keywords",
                        "sku",
                    ]}
                    columns={columns}
                    checkBox={{
                        checked: data.length === allSelectedIds.length,
                        onChangeCheckBox: (toggleAll) => {
                            toggleSelect({ toggle: toggleAll })
                        }
                    }}
                />
            </Box>
        </CardComponent>
    )
}

export default List;
