import { Box } from "@material-ui/core";
import { FbaFee, Order } from "common/types";
import OrderChart from "components/DashboardOrders/OrderChart";
import PeriodSelection from "components/shared/PeriodSelection";
import { TranslationPrefixes } from "consts/translationPrefixes";
import { endOfDay, startOfDay } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFees, getOrders } from "services/service";
import CardComponent from "../shared/CardComponent";

function WorkingDashboard() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [orders, setOrders] = useState<Order[]>([]);
    const [fees, setFees] = useState<FbaFee[]>([]);
    useEffect(() => {
        setIsLoading(true);
        Promise.all([getOrders(), getFees()]).then((val) => {
            setOrders(val[0]);
            setFees(val[1]);
            setIsLoading(false);
        }).catch(error => {
            console.error(error);
            setIsLoading(false); // Ensure loading state is reset in case of an error
        });
    }, []);

    const { t } = useTranslation();
    const { sideBar } = TranslationPrefixes;
    const [startDate, setStartDate] = useState<Date>(startOfDay(new Date())); // Default to start of today
    const [endDate, setEndDate] = useState<Date>(endOfDay(new Date())); // Default to end of today
    const handleDatesChange = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };
    return (
        <CardComponent title={t(`${sideBar}salesVisualizationNetProfit`)} isLoading={isLoading} chartPrint>
            <Box>
                <PeriodSelection onDatesChange={handleDatesChange} periods={["Today", "Last 30 days"]} />
                <OrderChart orders={orders} fees={fees} startDate={startDate} endDate={endDate} singleMetric="netProfit" />
            </Box>
        </CardComponent>
    )
}

export default WorkingDashboard;
