import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import { TranslationPrefixes } from 'consts/translationPrefixes';
import { addMonths, endOfMonth, isAfter, isSameMonth, startOfMonth, startOfYear } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import firebase, { base } from './firebase';
import CardComponent from './shared/CardComponent';

interface ReportData {
    reportType: string;
    status: 'fetching' | 'success' | 'error' | 'none';
    error?: string;
}

const ReportTable: React.FC = () => {
    const reportTypes = [
        // "GET_FLAT_FILE_OPEN_LISTINGS_DATA",
        // "GET_FLAT_FILE_ALL_ORDERS_DATA_BY_ORDER_DATE_GENERAL",
        // "GET_FBA_ESTIMATED_FBA_FEES_TXT_DATA",
        // "GET_SALES_AND_TRAFFIC_REPORT",
        'GET_FBA_FULFILLMENT_CUSTOMER_RETURNS_DATA',
        // "GET_FBA_MYI_UNSUPPRESSED_INVENTORY_DATA"
    ];

    const reportsWithoutDates = [
        'GET_FLAT_FILE_OPEN_LISTINGS_DATA',
        'GET_FBA_MYI_UNSUPPRESSED_INVENTORY_DATA',
        'GET_SALES_AND_TRAFFIC_REPORT'
    ];

    const initialReports: ReportData[] = reportTypes.map(reportType => ({
        reportType,
        status: 'none',
    }));

    const [reports, setReports] = useState<ReportData[]>(initialReports);
    const [loading, setLoading] = useState(false);
    const userId = firebase.getCurrentUser()?.uid;

    const fetchReportData = async (reportType: string, amazonCredentials: any) => {
        firebase.db.ref(`${base}/${userId}/lastFetch`).set(new Date().toLocaleString());

        const url = "http://54.196.110.7/generate_report";
        const payload: any = {
            report_type: reportType,
            marketplace: "US",
            credentials: amazonCredentials,
        };

        if (reportType === "GET_SALES_AND_TRAFFIC_REPORT") {
            payload['start_date'] = startOfYear(new Date()).toISOString();
            console.log("pay:", payload['start_date'])
        }

        if (!reportsWithoutDates.includes(reportType)) {
            const startDate = startOfYear(new Date());
            const now = new Date();
            let currentStartDate = startDate;
            const allData: any[] = [];
            const promises: Promise<any>[] = [];

            while (!isAfter(currentStartDate, now)) {
                let currentEndDate = endOfMonth(currentStartDate);

                if (isSameMonth(currentStartDate, now)) {
                    currentEndDate = now;
                }

                if (isAfter(currentEndDate, now)) {
                    break;
                }

                payload.start_date = currentStartDate.toISOString();
                payload.end_date = currentEndDate.toISOString();

                const promise = fetch(url, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload)
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`Error: ${response.statusText}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        if (!data?.error) {
                            allData.push(data);
                            return data;
                        } else {
                            return Promise.reject(data?.error);
                        }
                    })
                    .catch(error => {
                        console.error(`Error fetching data for ${reportType}:`, error);
                        throw error;
                    });

                promises.push(promise);
                currentStartDate = startOfMonth(addMonths(currentStartDate, 1));
            }

            try {
                await Promise.all(promises);

                // Merge all monthly data into a single dataset
                const mergedData = mergeMonthlyData(allData);

                // Store merged data in Firebase
                await firebase.db.ref(`${base}/${userId}/reports/${reportType}`).set(mergedData);
                setReportStatus(reportType, 'success');
            } catch (error) {
                console.error(`Error handling data for ${reportType}:`, error);
                setReportStatus(reportType, 'error', error.message || 'Unknown error');
            }
        } else {
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload)
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.statusText}`);
                }

                let data = await response.json();
                if (data?.salesAndTrafficByAsin) {
                    console.log("data1: ", data);
                    data = data.salesAndTrafficByAsin;
                    console.log("data: ", data);
                }
                await firebase.db.ref(`${base}/${userId}/reports/${reportType}`).set(data);
                setReportStatus(reportType, 'success');
            } catch (error) {
                console.error(`Error fetching data for ${reportType}:`, error);
                setReportStatus(reportType, 'error', error.message || 'Unknown error');
            }
        }
    };

    const setReportStatus = (reportType: string, status: 'fetching' | 'success' | 'error', error?: string) => {
        setReports(prevReports =>
            prevReports.map(report =>
                report.reportType === reportType ? { ...report, status, error } : report
            )
        );
    };

    const mergeMonthlyData = (monthlyData: any[]): any => {
        // Implement logic to merge data, e.g., concatenate arrays or merge objects
        // Example: Concatenate arrays of objects
        return monthlyData.reduce((merged, current) => merged.concat(current), []);
    };

    const fetchDataForAllReports = () => {
        setLoading(true);

        try {
            const userRef = firebase.db.ref(`${base}/${userId}/amazonCredentials`);
            userRef.once("value")
                .then(snapshot => {
                    const amazonCredentials = snapshot.val();

                    if (!amazonCredentials) {
                        console.warn(`No Amazon credentials found for current user.`);
                        throw new Error('No Amazon credentials found');
                    }

                    const reportPromises = reportTypes.map(reportType => {
                        setReportStatus(reportType, 'fetching');
                        return fetchReportData(reportType, amazonCredentials);
                    });

                    return Promise.all(reportPromises);
                })
                .catch(error => {
                    console.error(`Error fetching data:`, error);
                })
                .finally(() => {
                    if (reports.every(r => r.status === "success")) {
                        firebase.db.ref(`${base}/${userId}/lastFetch`).set(new Date().toLocaleString());
                    }
                    setLoading(false);
                });
        } catch (error) {
            console.error(`Error fetching data:`, error);
            setLoading(false);
        }
    };

    const handleGetAllReports = () => {
        fetchDataForAllReports();
    };

    const { t } = useTranslation();
    const { sideBar } = TranslationPrefixes;

    return (
        <CardComponent title={t(`${sideBar}fetchReports`)} isLoading={false}>
            <Box>
                <Grid container alignItems='center' spacing={2}>
                    <Grid item xs={12} md={9} style={{ display: "flex" }}>
                        <Typography>
                            {t('bar.fetchInfo')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGetAllReports}
                            style={{ marginBottom: 16, float: "right" }}
                            disabled={loading}
                            disableElevation
                            fullWidth
                        >
                            {t('bar.fetch')}
                            {loading && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
                        </Button>
                    </Grid>
                </Grid>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('bar.reportType')}</TableCell>
                                <TableCell>{t('bar.status')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reports.map(report => (
                                <TableRow key={report.reportType}>
                                    <TableCell>{report.reportType}</TableCell>
                                    <TableCell>
                                        {report.status === 'fetching' ? <CircularProgress size={24} /> :
                                            t('bar.' + report.status)
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </CardComponent>
    );
};

export default ReportTable;
