import { Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles, } from "@material-ui/core";
import {
	AddCircle,
	ExpandLess,
	ExpandMore,
	FeaturedPlayListOutlined,
	List as ListIcon,
	PlayArrowOutlined,
	TextFormat
} from "@material-ui/icons";
import { TranslationPrefixes } from "consts/translationPrefixes";
import React, { useEffect, useState } from "react";
import { WithTranslation, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { RoutesUrl } from "../../common/consts/Routes";
interface Route {
	route: string;
	label: string;
	icon: React.ReactNode;
	children?: Route[];
	type?: 'link' | 'route';
}

const getRoutes = (t: WithTranslation['t']): Route[] => {
	const { sideBar } = TranslationPrefixes
	return [
		// {
		// 	label: t(`${sideBar}dashboard`),
		// 	route: "dashboard",
		// 	icon: <DashboardOutlined />,
		// 	children: [
		// 		{
		// 			label: t(`${sideBar}ordersVisualization`),
		// 			icon: <Timeline />,
		// 			route: RoutesUrl.dashboard.orders,
		// 		},
		// 		{
		// 			label: t(`${sideBar}productsProfit`),
		// 			icon: <ListIcon />,
		// 			route: RoutesUrl.dashboard.productsProfit,
		// 		},
		// 		// {
		// 		// 	label: t(`${sideBar}profitBreakdown`),
		// 		// 	icon: <ListIcon />,
		// 		// 	route: RoutesUrl.dashboard.profitBreakdown,
		// 		// },
		// 		{
		// 			label: t(`${sideBar}salesVisualizationRevenue`),
		// 			icon: <Timeline />,
		// 			route: RoutesUrl.salesVisualisation.totalRevenue,
		// 		},
		// 		{
		// 			label: t(`${sideBar}salesVisualizationNetProfit`),
		// 			icon: <Timeline />,
		// 			route: RoutesUrl.salesVisualisation.netProfit,
		// 		},
		// 		{
		// 			label: t(`${sideBar}products`),
		// 			icon: <ListIcon />,
		// 			route: RoutesUrl.dashboard.products,
		// 		},
		// 		{
		// 			label: t(`${sideBar}inventory`),
		// 			icon: <ListIcon />,
		// 			route: RoutesUrl.dashboard.inventory,
		// 		},
		// 	]
		// },
		// {
		// 	label: t(`${sideBar}orders`),
		// 	route: RoutesUrl.orders,
		// 	icon: <LocalOfferOutlined />,
		// },
		// {
		// 	label: t(`${sideBar}cogs`),
		// 	route: RoutesUrl.dashboard.cogs,
		// 	icon: <AttachMoney />,
		// },
		{
			label: t(`${sideBar}keyWords`),
			route: RoutesUrl.keyWords.list,
			icon: <TextFormat />,
			children: [
				{
					label: t(`${sideBar}list`),
					icon: <ListIcon />,
					route: RoutesUrl.keyWords.list,
				},
				{
					label: t(`${sideBar}new`),
					icon: <AddCircle />,
					route: RoutesUrl.keyWords.create,
				},
			]
		},
		{
			label: t(`${sideBar}ads`),
			route: "ads",
			icon: <FeaturedPlayListOutlined />,
			children: [

				{
					label: t(`${sideBar}list`),
					icon: <ListIcon />,
					route: RoutesUrl.ads.list,
				},
				{
					label: t(`${sideBar}new`),
					icon: <AddCircle />,
					route: RoutesUrl.ads.create,
				},

			]
		},
		{
			label: t(`${sideBar}trainingLabel`),
			route: t(`${sideBar}trainingLink`),
			icon: <PlayArrowOutlined />,
			type: "link",
		},
		// {
		// 	label: t(`${sideBar}htmlEditor`),
		// 	route: RoutesUrl.editor,
		// 	icon: <Code />,
		// },
		// {
		// 	label: t(`${sideBar}reports`),
		// 	route: "business-reports",
		// 	icon: <DataUsageIcon />,
		// 	children: [
		// 		{
		// 			label: t(`${sideBar}inventory`),
		// 			icon: <ListIcon />,
		// 			route: RoutesUrl.reports.inventory,
		// 		},
		// 		{
		// 			label: t(`${sideBar}ordersVisualization`),
		// 			icon: <Timeline />,
		// 			route: RoutesUrl.reports.orders,
		// 		},
		// 	]
		// },

	]
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function SideMenu() {
	const location = useLocation();
	const history = useHistory();
	const { t } = useTranslation();

	const useStyles = makeStyles(({ breakpoints }) => ({
		icon: {
			minWidth: 30,
		},
		text: {
			// whiteSpace: "nowrap",
		},
		root: {
			"&$disabled": {
				opacity: 1,
			},
		},
		disabled: {

		},
		child: {
			position: "relative",
			left: "6px",
			top: "-6px",
			fontSize: "20px",
		},
		nested: {
			"& span": {
				fontStyle: "italic",
			}
		},
		childNested: {
			paddingLeft: 25,
		}
	}));

	const routes = getRoutes(t);
	const classes = useStyles();

	const [open, setOpen] = useState("");

	const handleClick = (r: Route) => {
		const { route } = r;
		if (route === open) {
			setOpen("");
		} else {
			setOpen(r?.route || "");
		}
	}

	useEffect(() => {
		for (const route of routes) {
			if (route.children !== undefined) {
				const find = route.children.find((r) => `${r.route}` === location.pathname);

				if (find) {
					setOpen(find ? route.route : "");
					break;
				}

			}

			if (route.route === location.pathname) {
				setOpen(route.route);
				break;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<List>
			{
				routes.map((r) => {
					if (r.children) {
						return (
							<div key={`${r.route}-container`}>
								<ListItem key={`${r.route}-dropdown-list-item`} button onClick={() => handleClick(r)} >
									<ListItemIcon style={{ minWidth: 30 }}>
										{r.icon}
									</ListItemIcon>
									<ListItemText primary={r.label} classes={{ root: classes.text }} />
									{open === r.route ? <ExpandLess /> : <ExpandMore />}
								</ListItem>
								<Collapse in={open === r.route} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{
											r.children.map((rC) => {
												const link = `${rC.route}`;
												return (
													<ListItem button key={rC.route} className={rC.icon ? classes.childNested : classes.nested} selected={location.pathname === link} classes={{ root: classes.root, disabled: classes.disabled }} disabled={!rC.route} onClick={() => (rC.route ? (history.push(link)) : false)}>
														{rC.icon && <ListItemIcon classes={{ root: classes.icon }}>{rC.icon}</ListItemIcon>}
														<ListItemText primary={rC.label} />
													</ListItem>
												)
											})
										}
									</List>
								</Collapse>
							</div>
						)
					}

					return (
						<ListItem button key={`${r.route}-normal-list-item`} selected={location.pathname === r.route} href={r.route}

							onClick={() => {
								if (r.type === "link") {
									window.open(r.route, '_blank')
								}
								return r.route ? (history.push(r.route)) : false
							}


							} disabled={r.route === undefined} classes={{ root: classes.root, disabled: classes.disabled }}>
							<ListItemIcon classes={{ root: classes.icon }}>{r.icon}</ListItemIcon>
							<ListItemText primary={r.label} classes={{ root: classes.text }} />
						</ListItem>
					)
				})
			}
		</List>
	);
}	
