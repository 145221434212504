import React, { ChangeEvent } from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props {
    label: string;
    option: string;
    checked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>, option: string) => void;
}

const OptionCheckbox = ({ label, option, checked, onChange }: Props) => {
    const { t } = useTranslation();

    return <Box key={label}>
        <FormControlLabel
            control={
                <Checkbox
                    checked={!!checked}
                    onChange={(e) => onChange(e, option)}
                />
            }
            label={t(label)}
        />
    </Box>
};

export default OptionCheckbox