import React from "react";
import { KeyWordsData } from "./KeyWordsList";

export interface KeywordsColumn {
    name: string;
    value: "sku" | "phrases" | "convertedPhrases";
}

type Props = {
    headers: Array<KeywordsColumn>;
    data: KeyWordsData[] | null;
    title?: string;
    ref: React.MutableRefObject<any>;
}

class KeyWordsPrint extends React.PureComponent<Props> {
    getContentTable(value: string | string[]) {
        if (Array.isArray(value)) {
            return <span dangerouslySetInnerHTML={{ __html: value.join(" ") }} />
        }

        return <span dangerouslySetInnerHTML={{ __html: value }} />
    }

    render() {
        const { headers, data, title } = this.props;

        if (!data) {
            return <div>Nothing to print</div>
        }

        return <div className="printMain" >
            {
                title && <h2>{title}</h2>
            }

            <div className="printTable">
                {
                    data.map((el) =>
                        <div key={el.id}>
                            <table>
                                <tbody>
                                    {
                                        headers.map(({ name: headerName, value: headerValue }) => {
                                            const value = headerValue && el[headerValue];
                                            if (value) {
                                                return (
                                                    <tr key={headerName}>
                                                        <th style={{ width: "40%" }} dangerouslySetInnerHTML={{ __html: headerName || "" }} />
                                                        <td>{this.getContentTable(value)}</td>
                                                    </tr>
                                                )
                                            }
                                        })}

                                </tbody>
                            </table>
                            <br />
                        </div>
                    )
                }
            </div>


        </div >
    }

};

export default KeyWordsPrint;