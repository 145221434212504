import {
    Button,
    Divider,
    Grid,
    GridSize,
    StandardTextFieldProps,
    TextField,
    Typography,
} from '@material-ui/core';
import { TranslationPrefixes } from 'consts/translationPrefixes';
import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface FieldConfig extends StandardTextFieldProps {
    name: string;
    maxSymbols?: number;
    required: boolean;
    gridSize?: GridSize;
    validation?: (value: any) => string | undefined;
    onChange?: () => void;
}

export type FormData = {
    [K in FieldConfig['name']]?: string | number | boolean;
};

type FormErrors = {
    [K in FieldConfig['name']]?: string;
};

interface MyFormProps {
    staticFields: FieldConfig[];
    onSubmit?: (data: FormData) => void;
    dynamicPlural?: string;
    dynamicSingle?: string;
    editMode?: boolean;
    formData: FormData;
    setFormData: React.Dispatch<React.SetStateAction<FormData>>
    onChange?: (data: FormData) => void;

}

const MyForm: React.FC<MyFormProps> = ({
    staticFields,
    onSubmit,
    editMode,
    setFormData,
    formData,
    onChange
}) => {
    const generateInitialFormErrors = useCallback((): FormErrors => {
        const initialFormErrors: FormErrors = {}; // Initialize with an empty array
        staticFields.forEach((field) => {
            initialFormErrors[field.name] = '';
        });
        return initialFormErrors;
    }, [staticFields]);

    const { t } = useTranslation();
    const { form } = TranslationPrefixes;
    const [formErrors, setFormErrors] = useState<FormErrors>(
        generateInitialFormErrors()
    );
    const validate = (): boolean => {
        let isValid = true;
        const newFormErrors: FormErrors = {};
        // Validate static fields
        staticFields.forEach((field) => {
            const value = formData[field.name];
            const errorMessage = field.validation ? field?.validation(value as string | number | boolean) : false;
            if (errorMessage) {
                newFormErrors[field.name] = errorMessage;
                isValid = false;
            }
        });

        setFormErrors(newFormErrors);
        return isValid;
    };

    const validateRequired = () => {
        let isValid = true;
        const newFormErrors: FormErrors = {};

        // Validate static fields
        staticFields.forEach((field) => {
            const value = formData[field.name];
            const errorMessage = field.required && !value ? t(`${form}required`) : false;
            if (errorMessage) {
                newFormErrors[field.name] = errorMessage;
                isValid = false;
            }
        });
        setFormErrors(newFormErrors);
        return isValid;
    }
    const handleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any,
        newValue?: string | null
    ): void => {
        const name = event.target.name || event.target.getAttribute('name');
        const value = event.target.value;
        setFormData((fd) => {
            fd[name] = newValue !== undefined ? newValue : value;
            onChange && onChange(fd);
            return fd;
        });
        validate();
    };
    const resetForm = useCallback((): void => {
        setFormData({});
        setFormErrors(generateInitialFormErrors());
    }, [generateInitialFormErrors, setFormData]);


    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (validate() && validateRequired()) {
            if (onSubmit) {
                onSubmit(formData);
            }
        }
    };
    return (
        <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={1} alignItems="flex-end">
                {staticFields.map((field) => {
                    return <Grid item xs={field.gridSize || 12} key={field.name}>
                        <TextField
                            id={field.name}
                            key={field.name}
                            name={field.name}
                            label={field.label}
                            type={field.type}
                            required={field.required}
                            value={formData?.[field.name] || field.value || ""}
                            defaultValue={field.value}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            error={Boolean(formErrors[field.name])}
                            helperText={formErrors[field.name]}
                            disabled={field?.disabled}
                            InputLabelProps={{ shrink: !!(formData?.[field.name] || field?.value || "") }}
                            multiline
                            minRows={1}
                        />
                        {field.maxSymbols && <Typography>{formData?.[field.name]?.toString()?.length || 0}/{field.maxSymbols}</Typography>}
                    </Grid>
                })}
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={editMode ? 12 : 6}>
                    <Button fullWidth type="submit" variant="contained" color="primary">
                        {editMode ? t(`${form}edit`) : t(`${form}add`)}
                    </Button>
                </Grid>
                {
                    !editMode && <Grid item xs={6}>
                        <Button
                            fullWidth
                            type="button"
                            onClick={resetForm}
                            variant="contained"
                            color="secondary"
                        >
                            {t(`${form}reset`)}
                        </Button>
                    </Grid>
                }
            </Grid>
        </form>
    );
};

export default MyForm;
