import { KeywordsColumn } from "components/KeyWords/KeyWordsPrint";
import { TranslationPrefixes } from "consts/translationPrefixes";
import moment from "moment";
import { WithTranslation } from "react-i18next";
import { ExportableColumn } from "../../components/ComponentToPrint/TableComponent";
import { Column } from "../../components/shared/TableComponent";
import DBExhibitions from "../../models/Exhibitions/Exhibitions";
import Manufacture from "../../models/manufacture/Manufacture";
import { DbInstrument } from "../../models/Storage/Instrument";
import { DbMachine } from "../../models/Storage/Machine";
import { DbMaterial } from "../../models/Storage/Material";
import { DbProduction } from "../../models/Storage/Production";
import { DbRawMaterial } from "../../models/Storage/RawMaterial";
import { momentFormat } from "./FormUtils";

export type WorkingTypes = DbMaterial | DbRawMaterial | DbProduction | DbInstrument | DbMachine;

export function getColumnsForExport(columns: Array<Column>): Array<ExportableColumn> {
	return columns.filter((c) => c.accessor).map((col) => {
		return {
			name: col.header,
			value: col.accessor
		}
	}) as Array<ExportableColumn>;
}

export function getDataForExport(data: WorkingTypes[]) {
	return data.map((item) => item.dataToSave());
}

export function getManufactureColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			value: "name",
			name: "Наименование",
		},
		{
			value: "quantity",
			name: "Количество",
		},
		{
			value: "startDate",
			name: "Начална дата",
		},
		{
			value: "endDate",
			name: "Крайна дата",
		},
	];
}

export function getManufactureDataForExport(data: Array<Manufacture>) {
	return data.map((item) => {
		return {
			name: item.name,
			quantity: item.quantity,
			startDate: moment(item.startDate).format(momentFormat),
			endDate: item.endDate ? moment(item.endDate).format(momentFormat) : "",
		}
	});
}

export function getDataBasesColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Наименование",
			value: "name"
		},
		{
			name: "Категория",
			value: "type"
		},
		{
			name: "ЕИК",
			value: "eik"
		},
		{
			name: "Регистрация по ДДС",
			value: "dds"
		},
		{
			name: "Услуги",
			value: "services"
		},
		{
			name: "Държава",
			value: "country"
		},
		{
			name: "Адрес",
			value: "address"
		},
		{
			name: "Лицa за контакт",
			value: "dynamicRows"
		},
		{
			name: "Сайт",
			value: "site"
		},
		{
			name: "Други",
			value: "others"
		},
	]
}
export function getBusinessColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Име",
			value: "name"
		},
		{
			name: "Категория",
			value: "category"
		},
		{
			name: "Запитвания",
			value: "queries"
		},
		{
			name: "Оферти",
			value: "offers"
		},
		{
			name: "Договори",
			value: "contracts"
		},
		{
			name: "Рекламации",
			value: "returns"
		},
		{
			name: "Референции",
			value: "references"
		},
		{
			name: "Забележки",
			value: "notes"
		},

	]
}

export function getPlaningColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Наименование",
			value: "name"
		},
		{
			name: "Категория",
			value: "type"
		},
		{
			name: "Раздел",
			value: "section"
		},
		{
			name: "Описание",
			value: "description"
		},
		{
			name: "Целева група",
			value: "targetGroup"
		},
		{
			name: "Забележки",
			value: "notes"
		},
	]
}

export function getExhibitionsColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Име на изложението",
			value: "name"
		},
		{
			name: "Тема",
			value: "topic"
		},
		{
			name: "Място",
			value: "place"
		},
		{
			name: "Посетител",
			value: "visitor"
		},
		{
			name: "Дата",
			value: "date"
		},
		{
			name: "Бележки",
			value: "notes"
		},
	]
}

export function getAdsColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Заглавие на обявата",
			value: "title"
		},
		{
			name: "Продуктова характеристика 1",
			value: "description1"
		},
		{
			name: "Продуктова характеристика 2",
			value: "description2"
		},
		{
			name: "Продуктова характеристика 3",
			value: "description3"
		},
		{
			name: "Продуктова характеристика 4",
			value: "description4"
		},
		{
			name: "Продуктова характеристика 5",
			value: "description5"
		},
		{
			name: "Описание на обявата",
			value: "adDescription"
		},
		{
			name: "Ключови думи за търсене",
			value: "keywords"
		},
		{
			name: "Каталожен номер на продукта (SKU)",
			value: "sku"
		},
	]
}


export function getDynamicColumnsForExport(args: { t: WithTranslation['t'], prefix: TranslationPrefixes, values: object }) {
	const { t, prefix, values } = args;

	return Object.values(values).map(el => ({
		name: t(`${prefix}${el}`),
		value: el
	}));
}



export function getKeyWordsColumnsForExport(t: WithTranslation['t']): Array<KeywordsColumn> {
	const { keyWordsList } = TranslationPrefixes;
	return [
		{
			name: t(`${keyWordsList}skuCatalog`),
			value: "sku"
		},
		{
			name: t(`${keyWordsList}phrases`),
			value: "phrases"
		},
		{
			name: t(`${keyWordsList}convertedPhrases`),
			value: "convertedPhrases"
		},
	]
}

export function getServicingColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Име Контрагент",
			value: "nameAd"
		},
		{
			name: "Категория",
			value: "category"
		},
		{
			name: "Наименование на поръчката",
			value: "orderName"
		},
		{
			name: "Гаранционен срок",
			value: "waranty"
		},
		{
			name: "Рекламации",
			value: "dynamicRows"
		},
		{
			name: "Референция",
			value: "wantedReference"
		},
	]
}

export function getAdsDataForExport(data: Array<any>) {
	return data.map(item => {
		return {
			...item.dataToSave(),
		}
	})
}

export function getExhibitionsDataForExport(data: Array<DBExhibitions>) {
	return data.map(item => {
		return {
			...item.dataToSave(),
		}
	})
}

export function getProcessColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Наименование",
			value: "name",
		},
		{
			name: "Статус",
			value: "state",
		},
		{
			name: "Дата на започване",
			value: "startDate",
		},
		{
			name: "Дата на приключване",
			value: "endDate",
		},
	];
}


export function getEmployeeColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Имена",
			value: "names"
		},
		{
			name: "Дата на започване",
			value: "start_date",
		},
		{
			name: "Позиция",
			value: "position",
		},
		{
			name: "Умения",
			value: "skills"
		},
		{
			name: "Други",
			value: "other",
		},
		{
			name: "Общо работни часове",
			value: "total_working_hours",
		}
	];
}

export function getColumns(columns: Array<ExportableColumn>): Array<any> {
	return columns.map((col) => {
		const label = col.name || '';
		const key = col.value || '';

		return {
			label,
			key,
		};
	});
}

