import {
    Badge,
    Box, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, Theme, WithStyles, createStyles, withStyles,
} from "@material-ui/core";
import { copyClipboard } from "common/Utils/text";
import { KeyWordsDB } from "components/KeyWords/types";
import { areMapsEqual } from "components/KeyWords/utils";
import { TranslationPrefixes } from "consts/translationPrefixes";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { RoutesUrl } from "../../common/consts/Routes";
import { find, findAndUpdate, getData, store } from "../../services/service";
import CardComponent from "../shared/CardComponent";
import FormComponent, { FormData } from "../shared/form";

const styles = ({ palette }: Theme) => {
    return createStyles({
        keywordsContainer: {
        },
        keyword: {
            margin: 5,
            padding: 10,
            background: palette.secondary.main,
            borderRadius: 10,
            color: palette.common.white,
            cursor: "pointer",
        }
    });
};

function Ads({ classes }: WithStyles) {
    let { id }: any = useParams();
    const { push } = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [keywords, setKeywords] = useState<KeyWordsDB[]>([]);
    const [selectedSKU, setSelectedSKU] = useState<string>("");
    const [optionKeywords, setOptionKeywords] = useState<Map<string, number> | null>(null);
    const [openSnack, setOpenSnack] = useState(false);
    const [formData, setFormData] = useState<FormData>({});

    const { t } = useTranslation();
    const { ads, adsFields } = TranslationPrefixes;

    useEffect(() => {
        getData("keywords").then(data => setKeywords(data));
    }, []);

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            find("ads", id).then((val) => {
                setFormData(val);
                setSelectedSKU(val.selectedSKU);
            }).finally(() => setIsLoading(false));
        }
    }, [id]);

    const checkForUsedKeywords = useCallback((data: FormData) => {
        if (!optionKeywords) {
            return;
        }

        const words = new Map<string, number>(optionKeywords);
        const allFieldsDataAsString = Object.values(data).join(" ");

        Array.from(optionKeywords.entries()).forEach(([word, _count]) => {
            const regex = new RegExp(`\\b${word}\\b`, 'g');
            const matches = allFieldsDataAsString.match(regex);
            const matchesWord = matches?.length ?? 0;
            words.set(word, matchesWord);
        });

        if (!areMapsEqual(words, optionKeywords)) {
            setOptionKeywords(words);
        }
    }, [optionKeywords])

    useEffect(() => {
        checkForUsedKeywords(formData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSKU]);

    const handleSKUchange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedSKU(event.target.value as string);
        const element = keywords.find(k => k.sku === event.target.value);

        if (element) {
            const words = new Map<string, number>();

            element.convertedPhrases.forEach(phrase => words.set(phrase, 0));
            setOptionKeywords(words)
        } else {
            setOptionKeywords(null);
        }
    };

    const onClickBadgeCopy = (phrase: string) => {
        copyClipboard(phrase).then(() => setOpenSnack(true));
    }

    const renderKeywords = () => {
        if (!optionKeywords) {
            return null;
        }

        return <Box className={classes.keywordsContainer}>
            {
                Array.from(optionKeywords.entries()).map(([word, count]) =>
                    <Badge className={classes.keyword} onClick={() => onClickBadgeCopy(word)} key={word} color="primary" badgeContent={count}>
                        {word}
                    </Badge>
                )
            }
        </Box>
    };

    type StaticField = {
        maxSymbols: number;
        name: string;
        label: string;
        type: string;
        required: boolean;
        validation?: (v?: string) => string | undefined;
    };

    const generateStaticField = ({ name, label, maxSymbols, type, required }: StaticField): StaticField => ({
        maxSymbols,
        name,
        label: t(`${adsFields}${label}`),
        type,
        required,
        validation: (v) => {
            if (v && v?.length > maxSymbols) {
                return t(`${adsFields}validation`, { symbols: maxSymbols });
            }
        },
    });

    const staticFields: StaticField[] = [
        generateStaticField({ name: 'title', label: 'title', maxSymbols: 200, type: 'text', required: true }),
        generateStaticField({ name: 'description1', label: 'product1', maxSymbols: 500, type: 'text', required: true }),
        generateStaticField({ name: 'description2', label: 'product2', maxSymbols: 500, type: 'text', required: false }),
        generateStaticField({ name: 'description3', label: 'product3', maxSymbols: 500, type: 'text', required: false }),
        generateStaticField({ name: 'description4', label: 'product4', maxSymbols: 500, type: 'text', required: false }),
        generateStaticField({ name: 'description5', label: 'product5', maxSymbols: 500, type: 'text', required: false }),
        generateStaticField({ name: 'adDescription', label: 'description', maxSymbols: 2000, type: 'text', required: true }),
        generateStaticField({ name: 'keywords', label: 'keywords', maxSymbols: 250, type: 'text', required: true }),
        generateStaticField({ name: 'sku', label: 'sku', maxSymbols: 100, type: 'text', required: true }),
    ];

    return (
        <Box>
            <CardComponent title={t(`${adsFields}add`)} isLoading={isLoading}>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="select-keywords-sku">{t(`${ads}keywordsSKU`)}</InputLabel>
                            <Select
                                labelId="select-keywords-sku"
                                value={selectedSKU}
                                label="Choose sku for keywords"
                                onChange={handleSKUchange}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 36 * 4 + 12,
                                        },
                                    },
                                }}
                            >
                                {
                                    keywords.map(({ sku }) =>
                                        <MenuItem key={sku} value={sku}>{sku}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                        {renderKeywords()}
                    </Grid>
                    <Grid item xs={10}>
                        <FormComponent
                            onChange={checkForUsedKeywords}
                            staticFields={staticFields}
                            onSubmit={async (data) => {
                                try {
                                    if (id) {
                                        await findAndUpdate("ads", id, { ...data, selectedSKU: selectedSKU });
                                        toast.success(t(`${ads}successfulEdit`));
                                        push(RoutesUrl.ads.list);
                                        return;
                                    }
                                    await store("ads", { ...data, selectedSKU: selectedSKU });
                                    toast.success(t(`${ads}successfulSaved`));
                                    push(RoutesUrl.ads.list);
                                    return true;
                                } catch (e) {
                                    toast.error(t(`${ads}error`));
                                    return false;
                                }
                            }}
                            editMode={id ? true : false}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </Grid>
                </Grid>
            </CardComponent>
            <Snackbar
                open={openSnack}
                autoHideDuration={1000}
                onClose={() => setOpenSnack(false)}
                message={t(`${ads}copiedToClipboard`)}
            />
        </Box>
    );
}

export default withStyles(styles)(Ads)
