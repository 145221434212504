import {
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TranslationPrefixes } from 'consts/translationPrefixes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	tableHeadRoot: {
		fontWeight: 600,
	},
	container: {
		maxHeight: 'calc(100vh - 290px)',
	},
	p: {
		'& p': {
			display: '-webkit-box',
			maxWidth: '200px',
			WebkitLineClamp: 4,
			WebkitBoxOrient: 'vertical',
			overflow: 'hidden',
		},
	},
});

export interface Column {
	header: string | 'checkBox';
	accessor?: string;
	cell: (cell: any, item?: any, accessor?: string) => JSX.Element;
}

interface Props {
	data: Array<any>;
	columns: Array<Column>;
	perPage?: number;
	rowElement?: (data: any, index: number) => JSX.Element;
	checkBox?: {
		onChangeCheckBox: (allChecked: boolean) => void;
		checked: boolean;
	};
	currentSearch?: string; // Make currentSearch a required prop
	searchKeys?: string[];
}

const TableComponent: React.FC<Props> = ({
	columns,
	data,
	perPage = 10,
	rowElement = undefined,
	checkBox,
	currentSearch = "", // Receive currentSearch as a prop
	searchKeys = ['sku', 'asin'],
}) => {
	const classes = useStyles();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(perPage);
	const { t } = useTranslation();
	const { table } = TranslationPrefixes;

	useEffect(() => {
		setPage(0); // Reset page when data or search term changes
	}, [data, currentSearch]);

	const filteredData = data.filter((item) =>
		searchKeys.some((key) =>
			item[key]?.toLowerCase().includes(currentSearch.toLowerCase())
		)
	);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<TableContainer className={classes.container}>
			<Table className={classes.table} stickyHeader>
				<TableHead>
					<TableRow>
						{columns.map((column, index) => (
							<TableCell
								key={index}
								component="th"
								style={{ verticalAlign: 'bottom' }}
								classes={{ root: classes.tableHeadRoot }}
							>
								{column.header === 'checkBox' ? (
									data.length > 0 ? (
										<Checkbox
											checked={checkBox ? checkBox.checked : false}
											onChange={() => {
												if (checkBox) {
													checkBox.onChangeCheckBox(!checkBox.checked);
												}
											}}
										/>
									) : (
										''
									)
								) : (
									column.header
								)}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{filteredData
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((item, index) => {
							if (rowElement !== undefined) {
								return rowElement(item, index);
							}

							return (
								<TableRow key={index}>
									{columns.map((column, columnIndex) => {
										const data = column.accessor
											? item[column.accessor]
											: item;

										return (
											<TableCell
												key={columnIndex}
												component="td"
												scope="row"
												style={{ verticalAlign: 'top' }}
												className={`${classes.p}`}
											>
												{column.cell(data, item, column.accessor)}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[
								5,
								10,
								25,
								{ label: t(`${table}all`), value: -1 },
							]}
							colSpan={12}
							count={filteredData.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true,
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelRowsPerPage={t(`${table}elementsTable`)}
							nextIconButtonText={t(`${table}nextPage`)}
							backIconButtonText={t(`${table}previousPage`)}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
};

export default TableComponent;
