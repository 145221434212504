import { Currency } from "interfaces/currency";

export function convertCurrency(currency = Currency.USD): (value: number) => number {
    return (value: number) => {
        const usdToEuro = 0.93;

        if (currency === Currency.USD) {
            return +value.toFixed(2);
        }

        return +(value * usdToEuro).toFixed(2);
    }

}
export const getExchangeRate = async (currency = Currency.USD): Promise<number> => {
    if (currency === Currency.USD) {
        return 1;
    }

    try {
        // Example API fetch (replace with your actual API fetch)
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
        const data = await response.json();
        return data.rates.EUR;
    } catch (error) {
        console.error('Failed to fetch exchange rate:', error);
        return 1;
    }
};

export const formatCurrency = (args: { currency?: Currency, euroExchnageRate?: number }) => {
    const { currency, euroExchnageRate } = args;

    return (value: number) => {
        if (!currency || !euroExchnageRate) {
            return +value.toFixed(2)
        }

        if (currency === Currency.EURO) {
            const val = value * euroExchnageRate
            return +val.toFixed(2);
        }

        return +value.toFixed(2)
    }
};

export const convertToCurrencyString = (currency?: Currency) => {

    return (value: number) => {
        if (currency === Currency.EURO) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'EUR',
            }).format(value)
        }

        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value)
    }

}

