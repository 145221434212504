import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
	onClose: () => void;
	onSuccess: () => void;
	children: JSX.Element;
	show: boolean;
	title: string;
}

export default function AlertModal(props: Props) {
	const { show, children, onSuccess, onClose, title } = props;

	const handleClose = () => {
		onClose();
	};
	const { t } = useTranslation()
	return (
		<React.Fragment>
			<Dialog
				open={show}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{children}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} >
						{t('actions.cancel')}
					</Button>
					<Button onClick={onSuccess} autoFocus>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
