import React, { useState } from "react";
import {
    createStyles, Box, Grid, Theme, TextField, Button,
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
    RouteComponentProps, withRouter,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import UploadIcon from '@material-ui/icons/CloudUpload';
import { ManipulationOptions } from "./types";
import OptionCheckbox from "./OptionCheckbox";
import { TranslationPrefixes } from "consts/translationPrefixes";

const styles = ({ palette }: Theme) => {
    return createStyles({
        leftSide: {
            flex: 1
        },
        rightSide: {
            flex: 1,
            "& > *:first-child": {
                marginBottom: 10
            }
        },
        textFieldContainer: {
            marginBottom: 20,
            padding: 10,
            width: "100%",
            "& > *": {
                width: "100%"
            }
        },
        createdWords: {
            height: "100%",
            border: "1px solid gray",
        },
        leftOptions: {},
        rightOptions: {},
        uploadButton: {
            marginBottom: 15,
            padding: "8px 12px",
            border: `1px solid ${palette.grey[400]}`
        },
        skuContainer: {
            marginBottom: 15
        }
    });
};

interface Props extends RouteComponentProps {
    onHandleAllOptionsChange: (event: React.ChangeEvent<HTMLInputElement>, option: keyof ManipulationOptions) => void;
    onConvertTextFieldInput: () => void;
    onHandleTextFieldWords: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onHandleChangeSKU: (event: React.ChangeEvent<HTMLInputElement>) => void;
    children?: React.ReactNode;
    editModeData?: {
        sku: string;
        phrases: string[];
    };
    isErrorSKU: boolean;
}

function WordsTextField(props: Props & WithStyles): JSX.Element {
    const { classes,
        editModeData,
        children,
        onHandleAllOptionsChange,
        onConvertTextFieldInput,
        onHandleTextFieldWords,
        onHandleChangeSKU,
        isErrorSKU
    } = props;
    const { t } = useTranslation();

    const { keywords } = TranslationPrefixes;

    const [allOptions, setAllOptions] = useState<{ label: string, checked: boolean, option: keyof ManipulationOptions }[]>([
        {
            label: `${keywords}removeDuplicates`,
            option: "removeDuplicates",
            checked: false
        },
        {
            label: `${keywords}wordsToLowerCase`,
            option: "lowerCase",
            checked: false
        },
        {
            label: `${keywords}wordsToUpperCase`,
            option: "upperCase",
            checked: false
        },
        {
            label: `${keywords}wordsAlphabetically`,
            option: "sortAlphabetically",
            checked: false
        },
        {
            label: `${keywords}singleLine`,
            option: "singleLine",
            checked: false
        },
        {
            label: `${keywords}allUpperCase`,
            option: "allUpperCase",
            checked: false
        }
    ]);

    const handleAllOptionsChange = (event: React.ChangeEvent<HTMLInputElement>, option: keyof ManipulationOptions) => {
        const newOptions = allOptions.map(el => {
            if (el.option === option) {
                return {
                    ...el,
                    checked: event.target.checked
                }
            }

            return el;
        });

        setAllOptions(newOptions);
        onHandleAllOptionsChange(event, option)
    };

    const getDefaultConvertedPhrasesText = () => {
        return editModeData?.phrases.join("\n").toString();
    };

    return <Box className={classes.leftSide}>
        <Box className="text field">
            {children}
            <Box className={classes.textFieldContainer}>
                <TextField
                    required
                    variant="outlined"
                    onChange={onHandleTextFieldWords}
                    className={classes.textField}
                    multiline
                    label={t(`${keywords}phrasesLabel`)}
                    rows={14}
                    defaultValue={getDefaultConvertedPhrasesText()}
                    InputLabelProps={{
                        shrink: !!getDefaultConvertedPhrasesText(),
                    }}
                />
            </Box>

            <Box className={classes.skuContainer}>
                <TextField
                    required
                    value={editModeData?.sku}
                    label={t(`${keywords}skuLabel`)}
                    placeholder={t(`${keywords}skuPlaceholder`)}
                    onChange={e => onHandleChangeSKU(e as React.ChangeEvent<HTMLInputElement>)}
                    error={isErrorSKU}
                />
            </Box>
            <Button className={classes.uploadButton}
                onClick={onConvertTextFieldInput}
                startIcon={<UploadIcon />}>
                {t(`${keywords}uploadWords`)}
            </Button>

            <Grid container>
                <Box className={classes.leftOptions}>
                    {allOptions.map(({ label, option, checked }) => (
                        <OptionCheckbox
                            key={label}
                            label={label}
                            option={option}
                            checked={!!checked}
                            onChange={(e) => handleAllOptionsChange(e, option)} />
                    ))}
                </Box>
            </Grid>
        </Box>
    </Box>
}

export default withRouter(withStyles(styles)(WordsTextField));
