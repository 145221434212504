import {
    Box,
    Button,
    FormControl,
    Grid,
    TextField,
    Theme,
    Typography,
    createStyles
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    RouteComponentProps, withRouter,
} from "react-router-dom";

import CloseIcon from '@material-ui/icons/Close';
import CardComponent from "components/shared/CardComponent";
import { TranslationPrefixes } from "consts/translationPrefixes";
import { toast } from "react-toastify";
import { getSingleData, storeSet } from "services/service";

const styles = ({ palette }: Theme) => {
    return createStyles({
        container: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            margin: "30px auto 0",
            width: "65%"
        },
        containerDisplayedData: {
            width: "65%",
            margin: "0 auto",
            padding: "30px 0",
            "& > *": {
                // display: "inline-block",
                // width: "50%",
                overflowWrap: "break-word"
            }
        },
        formControl: {
            width: "100%",
            marginBottom: 15,
            "& > *": {
                width: "100%"
            }
        },
        button: {
            width: 250
        },
        editButton: {
            marginTop: 20,
            width: 110
        },
        closeButton: {
            alignSelf: "flex-end"
        }
    });
};

export interface CredentialsData {
    refresh_token: string;
    lwa_app_id: string;
    lwa_client_secret: string;
}

function CredentialsForm(props: RouteComponentProps & WithStyles<typeof styles>): JSX.Element {
    const { classes } = props;
    const { t } = useTranslation();
    const [isEditMode, setIsEditMode] = useState(false);
    const [data, setData] = useState<CredentialsData>({
        lwa_app_id: "",
        lwa_client_secret: "",
        refresh_token: ""
    });
    const [isLoading, setIsLoading] = useState(true);

    const { credentialsForm } = TranslationPrefixes;

    const fetchStore = () => {
        getSingleData("amazonCredentials")
            .then((data) => {
                setIsLoading(false);
                if (data) {
                    setData(data as unknown as CredentialsData);
                }
            })
    }

    useEffect(() => {
        fetchStore();
    }, []);

    const updateCredentials = async () => {
        if (!data) {
            await storeSet("amazonCredentials", data);
            toast.success(t(`${credentialsForm}added`));
            fetchStore();
        } else {
            await storeSet("amazonCredentials", data);
            toast.success(t(`${credentialsForm}updated`));
            setIsEditMode(false);
            setData(data);
        }
    }

    const checkButtonDisable = () => {
        const areFilledFields = Object.values(data).every(Boolean);

        if (!areFilledFields) {
            return true;
        }

        return false;
    }

    interface FormInput {
        label: string;
        id: string;
        onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
        value: string;
        multiline?: boolean;
    }

    const formControl = ({ label, id, multiline, onChange, value }: FormInput) => {
        return <Box className={classes.formControl}>
            <FormControl>
                <TextField
                    label={label}
                    onChange={(e) => onChange(e)}
                    id={id}
                    aria-describedby="my-helper-text"
                    multiline={multiline} value={value} />
            </FormControl>
        </Box>
    }

    const { lwa_app_id, lwa_client_secret, refresh_token } = data;

    const inputs: FormInput[] = [
        {
            label: t(`${credentialsForm}refreshToken`),
            id: "refreshToken",
            value: refresh_token,
            onChange: ({ currentTarget }) => {
                setData({
                    ...data,
                    refresh_token: currentTarget.value
                })
            },
            multiline: true
        },
        {
            label: t(`${credentialsForm}appId`),
            id: "appId",
            value: lwa_app_id,
            onChange: ({ currentTarget }) => {
                setData({
                    ...data,
                    lwa_app_id: currentTarget.value
                })
            }
        },
        {
            label: t(`${credentialsForm}clientSecret`),
            id: "clientSecret",
            value: lwa_client_secret,
            onChange: ({ currentTarget }) => {
                setData({
                    ...data,
                    lwa_client_secret: currentTarget.value
                })
            }
        },
    ];

    const renderText = (string1: string, string2: string) => {
        return <> <Grid item xs={6}>
            <Typography variant="h6">{string1}</Typography>
        </Grid>
            <Grid item xs={6}>
                <Typography>{string2}</Typography>
            </Grid>
        </>
    }
    const renderForm = () => {
        if (data && !isEditMode) {
            return <Grid spacing={2} container className={classes.containerDisplayedData}>
                {renderText(t(`${credentialsForm}refreshToken`), data.refresh_token)}
                {renderText(t(`${credentialsForm}appId`), data.lwa_app_id)}
                {renderText(t(`${credentialsForm}clientSecret`), data.lwa_client_secret)}
                <Button className={classes.editButton} onClick={() => setIsEditMode(true)} color="primary" variant="contained">{t(`${credentialsForm}edit`)}</Button>
            </Grid>
        }

        return <Box className={classes.container}>
            {data && <Button className={classes.closeButton} onClick={() => setIsEditMode(false)}><CloseIcon /></Button>}
            {inputs.map(formControl)}
            <Button
                color="primary"
                variant="contained"
                className={classes.button}
                disabled={checkButtonDisable()}
                onClick={updateCredentials}>{data ? t(`${credentialsForm}update`) : t(`${credentialsForm}add`)}
            </Button>
        </Box>
    }
    return <CardComponent title={t(`${credentialsForm}title`)} isLoading={isLoading}>
        <>
            {renderForm()}
        </>
    </CardComponent>;

}

export default withRouter(withStyles(styles)(CredentialsForm));
