import {
	Box,
	Divider,
	Grid,
	Hidden,
	Theme,
	Typography,
	createStyles,
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import Cogs from "components/Cogs";
import DashboardOrders from "components/DashboardOrders/index";
import DashboardProducts from "components/DashboardProductsPerformance";
import DashboardProductsProfit from "components/DashboardProductsProfit";
import DashboardReports from "components/DashboardReports";
import HtmlEditor from "components/HtmlEditor/HtmlEditor";
import Inventory from "components/Inventory";
import Orders from "components/Orders";
import ProfitBreakdown from "components/ProfitBreakdown";
import ReportInventory from "components/ReportInventory";
import NetProfit from "components/SalesVisualization/NetProfit";
import TotalRevenue from "components/SalesVisualization/TotalRevenue";
import { TranslationPrefixes } from "consts/translationPrefixes";
import React from "react";
import { useTranslation } from "react-i18next";
import {
	Redirect,
	Route,
	RouteComponentProps,
	Switch,
	withRouter,
} from "react-router-dom";
import logoFooter from "../../assets/erasmus.png";
import logo from "../../assets/logo.png";
import parner1 from "../../assets/parner1.png";
import parner2 from "../../assets/parner2.png";
import { RoutesUrl } from "../../common/consts/Routes";
import Ads from "../Ads/Ads";
import AdsList from "../Ads/AdsList";
import CredentialsForm from "../CredentialsForm/CredentialsForm";
import KeyWords from "../KeyWords/KeyWords";
import KeyWordsList from "../KeyWords/KeyWordsList";
import AccountMenu from "../shared/AccountMenu";
import SideMenu from "../shared/SideMenu";

const styles = ({ palette, breakpoints }: Theme) => {
	return createStyles({
		"@global": {
			"html, body, #root": {
				width: "100%",
				height: "100%",
			},
		},
		body: {
			height: "100%",
			backgroundColor: palette.background.default,
			[breakpoints.down("sm")]: {
				display: "block",
			},
			[breakpoints.up("sm")]: {
				"> *": {
					minHeight: "100%",
				},
			},
		},
		paper: {
			[breakpoints.up("md")]: {
				maxWidth: "300px"
			},
			backgroundColor: palette.background.paper,
			padding: "1px",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between"
		},
		content: {
			flex: 1,
			overflow: "auto",
		},
		default: {
			background: "#fff",
			padding: "1px 0px",
			"& img": {
				background: "#fff"
			}
		},
		footer: {
			"&  p": {
				fontsize: 14
			}
		}
	});
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function Dashboard(props: RouteComponentProps & WithStyles): JSX.Element {
	const { classes } = props;

	const { t } = useTranslation();
	return (
		<Grid container className={classes.body} wrap="nowrap">
			<Box className={`${classes.paper} noPrint`}>
				<Box>
					<Box className={classes.default}>
						<img src={logo} alt="logo" style={{
							height: 90,
							margin: "20px auto 10px",
							display: "block",
						}} />
					</Box>
					<AccountMenu />
					<Hidden smDown>
						<Box paddingX="12px"><Divider variant="fullWidth" color="primary" /></Box>
						<SideMenu />
					</Hidden>
				</Box>
				<Box padding={2} className={classes.info}>
					<Typography align="center" >
						{t(`${TranslationPrefixes.footer}partners`)}
						<br />
						<br />
						<img src={parner1} style={{
							height: 90,
							margin: "0 auto 0",
							display: "block",
						}} />
						<br />
						<img src={parner2} style={{
							height: 90,
							margin: "0 auto 0",
							display: "block",
						}} />
					</Typography>
				</Box>
			</Box>

			<Box padding="17px 20px" overflow="auto" flex="auto">

				<Switch>
					<Route path={RoutesUrl.dashboard.orders} component={DashboardOrders} />
					<Route path={RoutesUrl.dashboard.products} component={DashboardProducts} />
					<Route path={RoutesUrl.dashboard.productsProfit} component={DashboardProductsProfit} />
					<Route path={RoutesUrl.dashboard.profitBreakdown} component={ProfitBreakdown} />
					<Route path={RoutesUrl.salesVisualisation.totalRevenue} component={TotalRevenue} />
					<Route path={RoutesUrl.salesVisualisation.netProfit} component={NetProfit} />
					<Route path={RoutesUrl.dashboard.reports} component={DashboardReports} />
					<Route path={RoutesUrl.dashboard.cogs} component={Cogs} />
					<Route path={RoutesUrl.ads.list} component={AdsList} />
					<Route path={RoutesUrl.ads.create} component={Ads} />
					<Route path={RoutesUrl.ads.edit()} component={Ads} />
					<Route path={RoutesUrl.keyWords.list} component={KeyWordsList} />
					<Route path={RoutesUrl.credentialsForm} component={CredentialsForm} />
					<Route path={RoutesUrl.keyWords.create} component={KeyWords} />
					<Route path={RoutesUrl.keyWords.edit()} component={KeyWords} />
					<Route path={RoutesUrl.orders} component={Orders} />
					<Route path={RoutesUrl.reports.orders} component={DashboardOrders} />
					<Route path={RoutesUrl.dashboard.inventory} component={Inventory} />
					<Route path={RoutesUrl.reports.inventory} component={ReportInventory} />
					<Route path={RoutesUrl.editor} component={HtmlEditor} />
					<Route path="*">
						<Redirect to={RoutesUrl.keyWords.list} />
					</Route>
				</Switch>
				{/* <StatusBar /> */}
				<Box sx={{
					fontSize: "16px", display: "flex", alignItems: "flex-end", marginTop: "50px",
					width: 1000,
					maxWidth: "100%",
					marginBottom: "10px",
				}}>

					<Typography  >
						<strong>
							{t(`${TranslationPrefixes.footer}title`)}
						</strong>
					</Typography>

					{/* <img src={twoImg} height="100px" width="auto" style={{ marginLeft: "30px" }}></img> */}
				</Box>
				<Box sx={{
					fontSize: "16px", display: "flex", alignItems: "flex-end", marginBottom: "20px",
					width: 1000,
					maxWidth: "100%",

				}}>
					<img src={logoFooter} height="60px" width="auto" style={{ marginRight: "30px" }}></img>
					<Typography>
						Agreement No: 2023-1-BG01-KA210-VET-000165566 <br />
						Key Action 2, Strategic Partnerships in VET Education <br />
						Under Erasmus+ Programme
					</Typography>
				</Box>
			</Box>

		</Grid >
	);
}

export default withRouter(withStyles(styles)(Dashboard));
